import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import colors from 'core/theme/colors';

interface Props {
  levels: {
    levelOne: string;
    levelTwo: string;
  }
}

const styles = {
  breadCrumb: {
    marginBottom: '30px',
    '& .MuiBreadcrumbs-ol .MuiBreadcrumbs-li .MuiTypography-root': {
      fontSize: '0.875rem',
      color: colors.gray400,
    },
    '& .MuiBreadcrumbs-ol .MuiBreadcrumbs-li:nth-of-type(1) .MuiTypography-root': {
      color: colors.documentHeaderDark,
    },
    '& .MuiBreadcrumbs-ol .MuiBreadcrumbs-separator': {
      fontSize: '1.25rem',
      color: colors.gray400,
    },
  },
};

export const BreadCrumb = ({ levels }: Props) => (
  <Breadcrumbs
    separator={<NavigateNextIcon />}
    aria-label="breadcrumb"
    sx={styles.breadCrumb}
  >
    <Typography data-testid="level-one">{levels.levelOne}</Typography>
    <Typography data-testid="level-two">{levels.levelTwo}</Typography>
  </Breadcrumbs>
);
