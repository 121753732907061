import { ReactNode, useReducer } from 'react';
import {
  AuthContext, ReducerAction, ReducerActionType, UserLoginType,
} from 'core';
import {
  getDataLocalStorage, setDataLocalStorage,
} from 'core/services';

const initialState = {
  accessToken: null,
  currentUser: null,
};

export const authReducer = (state: UserLoginType, action: ReducerAction) => {
  switch (action.type) {
  case ReducerActionType.LOGIN:
    return {
      ...state,
      accessToken: action.payload?.accessToken,
      currentUser: action.payload?.currentUser,
    };
  case ReducerActionType.LOGOUT:
    return { ...state, accessToken: null };
  default:
    return state;
  }
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const accessToken = getDataLocalStorage('accessToken');
  const currentUser = getDataLocalStorage('currentUser');
  const login = (data?: UserLoginType) => {
    if (data) {
      setDataLocalStorage('currentUser', data.currentUser);
      setDataLocalStorage('accessToken', data.accessToken);
      dispatch({
        type: ReducerActionType.LOGIN,
        payload: data,
      });
    }
  };

  const logout = async () => {
    localStorage.clear();
    dispatch({ type: ReducerActionType.LOGOUT });
  };

  const value = {
    accessToken: accessToken || state.accessToken,
    currentUser: currentUser || state.currentUser,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
