import { Typography } from '@mui/material';
import { useContext } from 'react';
import { InView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'core';
import { SupplierOrderStatus } from 'core/definitions/types';
import { GetSupplierOrders } from 'domains/SupplierOrders';
import { SupplierOrdersTable } from 'domains/SupplierOrders/ui';
import { BreadCrumb, PageNotFound } from 'shared/ui';
import 'intersection-observer';

const styles = {
  title: {
    marginBottom: '30px',
  },
};

type ParamsRouteProps = {
  supplierOrderStatus: string;
}

interface Levels {
  levelOne: string;
  levelTwo: string;
}

interface DataBasedOnSupplierOrderStatus {
  levels: Levels,
}

const FETCHED_ELEMENTS_COUNT_STEP = 15;

const dataBasedOnSupplierOrderStatus: Record<string, DataBasedOnSupplierOrderStatus> = {
  'pending-validation': {
    levels: {
      levelOne: 'État des commandes',
      levelTwo: 'Commandes en attente de validation',
    },
  },
};

export const SupplierOrders = () => {
  const { supplierOrderStatus } = useParams<ParamsRouteProps>();
  const authContext = useContext(AuthContext);

  const supplierOrdersQueryParams = {
    variables: {
      offset: 0,
      limit: FETCHED_ELEMENTS_COUNT_STEP,
      filter: {
        status: SupplierOrderStatus.PendingValidation,
        supplierId: authContext?.currentUser?.supplier.id,
      },
    },
  };

  const {
    loading,
    error,
    data,
    fetchMore,
  } = GetSupplierOrders(supplierOrdersQueryParams);
  const supplierOrders = data ? data.supplierOrdersPaginated.supplierOrders : [];
  const supplierOrdersTotal = data ? data.supplierOrdersPaginated.total : 0;

  return (
    <>
      {
        supplierOrderStatus && dataBasedOnSupplierOrderStatus[supplierOrderStatus]
          ? (
            <>
              <BreadCrumb levels={dataBasedOnSupplierOrderStatus[supplierOrderStatus].levels} />
              <Typography variant="h2" data-testid="page-title" sx={styles.title}>
                {dataBasedOnSupplierOrderStatus[supplierOrderStatus].levels.levelTwo}
                {(!error && !loading) ? (
                  <>
                    &nbsp;&#40;
                    {supplierOrdersTotal}
                    &#41;
                  </>
                ) : null}
              </Typography>
              {(!error && !loading)
                ? (
                  <>
                    <SupplierOrdersTable
                      data={supplierOrders}
                    />
                    <InView
                      onChange={(inView) => {
                        if (
                          inView
                          && supplierOrders.length < supplierOrdersTotal
                        ) {
                          fetchMore({
                            variables: {
                              offset: supplierOrders.length,
                            },
                          });
                        }
                      }}
                    />
                  </>
                )
                : null}
            </>
          )
          : <PageNotFound />
      }
    </>
  );
};
