import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LogoutIcon from '@mui/icons-material/Logout';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import {
  Box, Drawer, List, Toolbar, Typography, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import colors from 'core/theme/colors';
import { MenuElement } from 'domains/App/ui';
import { styles } from 'domains/App/ui/SideBar/SideBar.style';

interface Props {
  logOut: (event: React.MouseEvent<HTMLElement>) => void;
  supplierName: string;
}

export const SideBar = ({ logOut, supplierName }: Props) => (
  <Drawer variant="permanent" anchor="left" sx={styles.sideBar}>
    <Toolbar sx={styles.appSignature}>
      <Typography variant="h6" noWrap color={colors.white}>
        Powered By Nanotera
      </Typography>
    </Toolbar>
    <Box component="div" sx={styles.profileContainer}>
      <Typography variant="h4" color={colors.white}>
        {supplierName}
      </Typography>
    </Box>
    <List>
      <Box component="div" sx={styles.menuTitle}>
        <Typography component="span">
          État des devis
        </Typography>
      </Box>
      <MenuElement
        label="En attente de chiffrage"
        link="/quotes/pending-costing"
        icon={<HourglassEmptyIcon />}
        sx={styles.subMenu}
      />
      <MenuElement
        label="Réalisés"
        link="/quotes/complete"
        icon={<CheckCircleOutlinedIcon />}
        sx={styles.subMenu}
      />
    </List>
    <List>
      <Box component="div" sx={styles.menuTitle}>
        <Typography component="span">
          État des commandes
        </Typography>
      </Box>
      <MenuElement
        label="En attente de validation"
        link="/orders/pending-validation"
        icon={<HourglassEmptyIcon />}
        sx={styles.subMenu}
      />
      <MenuElement
        label="En cours de fabrication"
        link="/orders/pending-production"
        icon={<TimelapseIcon />}
        sx={styles.subMenu}
      />
      <MenuElement
        label="Terminées"
        link="/orders/complete"
        icon={<FlagOutlinedIcon />}
        sx={styles.subMenu}
      />
      <MenuElement
        label="Annulées"
        link="/orders/canceled"
        icon={<CancelOutlinedIcon />}
        sx={styles.subMenu}
      />
    </List>
    <List>
      <Box component="div" sx={styles.menuTitle}>
        <Typography component="span">
          Suivi citeo
        </Typography>
      </Box>
    </List>
    <List>
      <Box component="div" sx={styles.menuTitle}>
        <Typography component="span">
          Intégration des factures
        </Typography>
      </Box>
    </List>
    <List sx={styles.bottomMenu}>
      <ListItemButton sx={styles.subMenu} onClick={logOut}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Déconnexion" />
      </ListItemButton>
    </List>
  </Drawer>
);
