import { Box } from '@mui/material';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthContext } from 'core';
import { apolloClient, getDataLocalStorage } from 'core/services';
import colors from 'core/theme/colors';
import { SideBar, TopBar } from 'domains/App/ui';

const styles = {
  page: {
    display: 'flex',
    height: '100%',
    backgroundColor: colors.bodyBackgroundLight,
  },
  main: {
    width: '100%',
    padding: '70px 65px',
  },
};

export const App = () => {
  const authContext = useContext(AuthContext);
  const logOut = () => {
    authContext?.logout().then(() => apolloClient.clearStore());
  };
  const currentUser = getDataLocalStorage('currentUser');
  const supplierName = currentUser?.supplier?.name;
  return (
    <Box sx={styles.page}>
      <SideBar logOut={logOut} supplierName={supplierName} />
      <TopBar />
      <Box component="main" sx={styles.main}>
        <Outlet />
      </Box>
    </Box>
  );
};
