import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { AuthContext } from 'core';
import { LoginInput } from 'core/definitions/types';
import { LOGIN } from 'domains/Login/graphql/mutations';
import { LoginMutation, LoginMutationVariables } from 'domains/Login/graphql/mutations.definitions';

export const useLogin = () => {
  const [login] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN);
  const authContext = useContext(AuthContext);
  return async (loginInput: LoginInput) => {
    const result = await login({ variables: { data: loginInput } });

    if (!result.data) throw new Error();

    authContext?.login({
      accessToken: result.data?.login.accessToken,
      currentUser: result.data?.login.user,
    });
    return result;
  };
};
