import { useQuery } from '@apollo/client';
import { SupplierOrderFilterInput } from 'core/definitions/types';
import { QUERY_SUPPLIER_ORDERS } from 'domains/SupplierOrders/graphql/queries';
import {
  GetSupplierOrdersQuery, GetSupplierOrdersQueryVariables,
} from 'domains/SupplierOrders/graphql/queries.definitions';

export const GetSupplierOrders = (
  params: {
    variables: {
      offset: number,
      limit: number,
      filter?: SupplierOrderFilterInput,
    }
  },
) => useQuery<GetSupplierOrdersQuery, GetSupplierOrdersQueryVariables>(
  QUERY_SUPPLIER_ORDERS,
  params,
);
