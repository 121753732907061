const colors = {
  bodyBackgroundLight: '#fafafa',
  white: '#ffffff',
  error: '#f65a5a',
  gray: '#8b9898',
  black: '#000000',
  yellow: '#fec800',
  documentHeaderDark: '#3b3b50',
  documentHeaderDarkActive: '#58586a',
  primary: '#3e64ad',
  gray300: '#dadada',
  gray400: '#afb1b8',
  gray500: '#767684',
  blue: '#2e46d1',
  lightBlue: '#64c1c0',
  orange: '#ff9200',
  lightOrange: '#fef4e6',
};

export default colors;
