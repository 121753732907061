import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'core';
import Router from 'core/Router';
import { apolloClient } from 'core/services';
import { selectTheme } from 'core/theme/theme';

const Core = () => (
  <BrowserRouter>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={selectTheme()}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </ThemeProvider>
    </ApolloProvider>
  </BrowserRouter>
);
export default Core;
