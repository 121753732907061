import { createContext } from 'react';
import { UserLoginType } from 'core';
import { User } from 'core/definitions/types';

interface AuthContextType {
	accessToken?: string;
	currentUser?: User;
	login: (data: UserLoginType) => void;
	logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);
