export const formatDateToShortFrench = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getEarliestDateInArray = (dates: Date[]): Date => dates.reduce((c, n) => (n < c ? n : c));

export const displayElapsedTimeBetweenDates = (since: Date, to: Date = new Date(Date.now())): string => {
  const timeDiffInMinutes = Math.floor((to.getTime() - since.getTime()) / 60000);
  const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);

  const completeMinutesCount = timeDiffInMinutes % 60;
  const completeHoursCount = timeDiffInHours % 24;
  const completeDaysCount = Math.floor(timeDiffInHours / 24);

  const daysWording = completeDaysCount > 1 ? ' jours, ' : ' jour, ';
  const hoursWording = completeHoursCount > 1 ? ' heures, ' : ' heure, ';
  const minutesWording = completeMinutesCount > 1 ? ' minutes' : ' minute';

  const daysDisplay = completeDaysCount ? `${completeDaysCount}${daysWording}` : '';
  const hoursDisplay = completeDaysCount || completeHoursCount ? `${completeHoursCount}${hoursWording}` : '';
  const minutesDisplay = `${completeMinutesCount}${minutesWording}`;

  return `${daysDisplay}${hoursDisplay}${minutesDisplay}`;
};

export const isDateMoreThanNHoursAgo = (date: Date, n: number): boolean => {
  const now = new Date(Date.now());
  return date.getTime() < now.setHours(now.getHours() - n);
};
