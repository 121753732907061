import { createTheme } from '@mui/material/styles';
import { CSSProperties } from '@mui/material/styles/createTypography';
import colors from './colors';
import './fonts.scss';

const borderRadius = '200px';

type Fonts = {
  h1: CSSProperties;
  h2: CSSProperties;
  h3: CSSProperties;
  h4: CSSProperties;
  h5: CSSProperties;
  h6: CSSProperties;
  body1: CSSProperties;
  body2: CSSProperties;
  button: CSSProperties;
};

export const fonts: Fonts = {
  h1: {
    // 24px
    fontFamily: 'ScandiaWebBold',
    fontSize: '1.5rem',
    lineHeight: '2.125rem',
  },
  h2: {
    // 20px
    fontFamily: 'ScandiaWebBold',
    fontSize: '1.25rem',
    lineHeight: '2rem',
  },
  h3: {
    // 16px
    fontFamily: 'ScandiaWebMedium',
    fontSize: '1rem',
    lineHeight: '1.75rem',
  },
  h4: {
    // 14px
    fontFamily: 'ScandiaWebMedium',
    fontSize: '0.875rem',
    lineHeight: '1.625rem',
  },
  h5: {
    // 14px
    fontFamily: 'ScandiaWebRegular',
    fontSize: '0.875rem',
    lineHeight: '1.625rem',
  },
  h6: {
    // 12px
    fontFamily: 'ScandiaWebMedium',
    fontSize: '0.75rem',
    lineHeight: '1.5rem',
  },
  body1: {
    // 12px
    fontFamily: 'ScandiaWebRegular',
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
  },
  body2: {
    // 10px
    fontFamily: 'ScandiaWebRegular',
    fontSize: '0.625rem',
    lineHeight: '1.125rem',
  },
  button: {
    // 14px
    fontFamily: 'ScandiaWebMedium',
    fontSize: '0.875rem',
  },
};

const {
  h1, h2, h3, h4, h5, h6, body1, body2, button,
} = fonts;

export const selectTheme = () => createTheme({
  typography: {
    fontFamily: [
      'ScandiaWebRegular',
      'ScandiaWebMedium',
      'ScandiaWebLight',
      'ScandiaWebBold',
      'ScandiaWebBoldItalic',
      'ScandiaWebLightItalic',
      'ScandiaWebMediumItalic',
      'ScandiaWebRegularItalic',
      'ScandiaWebStencil',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    body1,
    body2,
    button: {
      ...button,
      textTransform: 'unset',
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: 10,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius,
          fontFamily: fonts.button.fontFamily,
          fontSize: fonts.button.fontSize,
        },
      },
    },
  },
});
