import {
  ListItemButton, ListItemIcon, ListItemText, SxProps,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { styles } from 'domains/App/ui/MenuElement/MenuElement.style';

interface Props {
  label: string;
  link: string;
  icon: JSX.Element;
  sx: SxProps;
}

export const MenuElement = ({
  label, link, icon, sx,
}: Props) => (
  <NavLink
    to={link}
    style={styles.link}
    children={({ isActive }) => (
      <ListItemButton sx={isActive ? { ...styles.activeSubMenu, ...sx } : sx}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    )}
  />
);
