import { gql } from '@apollo/client';

export const QUERY_SUPPLIER_ORDERS = gql`
    query GetSupplierOrders(
      $offset: Float!,
      $limit: Float!,
      $filter: SupplierOrderFilterInput,
    ) {
        supplierOrdersPaginated(
          offset: $offset,
          limit: $limit,
          filter: $filter
        ) {
          supplierOrders {
            id
			      status
            createdAt
            order {
                elepvContactName
                orderItem {
                    id
                    productId
                    expectedDeliveryDate
                    buyingPriceHt
                    shippingAddress {
                        name
                        firstAddress
                        zipCode
                        country
                        city
                        deliveryDepartment
                    }
                }
                fileReference {
                    id
                }
            }
          }
          total
        }
    }
`;
