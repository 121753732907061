import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'core';
import { App } from 'domains';

export const AccessControl = () => {
  const authContext = useContext(AuthContext);

  if (!authContext || !authContext.accessToken) {
    return <Navigate to="/login" replace />;
  }
  return <App />;
};
