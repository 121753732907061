import colors from 'core/theme/colors';

const drawerWidth = 240;

export const styles = {
  sideBar: {
    height: '100%',
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      backgroundColor: colors.documentHeaderDark,
      width: drawerWidth,
      boxSizing: 'border-box',
      '& .MuiToolbar-root': {
        minHeight: '50px',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '25px',
        color: colors.gray400,
        '& .MuiSvgIcon-root': {
          fontSize: '1rem',
        },
      },
    },
  },
  bottomMenu: {
    marginTop: 'auto',
  },
  profileContainer: {
    padding: '30px 16px',
  },
  appSignature: {
    justifyContent: 'center',
  },
  menuTitle: {
    color: colors.gray500,
    padding: '5px 16px',
    fontWeight: 500,
    fontSize: '0.75rem',
    '& span': {
      textTransform: 'uppercase',
    },
  },
  subMenu: {
    padding: '8px 16px',
    color: colors.gray400,
    '& span': {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
  },
};
