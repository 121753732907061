import colors from 'core/theme/colors';

const drawerWidth = 240;

export const styles = {
  topBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    paddingLeft: '15px',
    minHeight: '50px',
    backgroundColor: colors.blue,
    '& .MuiToolbar-root': {
      minHeight: '50px',
    },
    '& img': {
      margin: '0 5%',
    },
  },
};
