export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type AdditionalMargins = {
  __typename?: 'AdditionalMargins';
  autrePrixFixe: MarginType;
  distributions?: Maybe<Array<Distribution>>;
  prixCalage: MarginType;
  prixChangementPlaque: MarginType;
  prixCliche: MarginType;
  prixExemplaireSupp: MarginType;
  prixHorsCalage: MarginType;
  prixOutillage: MarginType;
  prixTraitementFichier: MarginType;
};

export type AdditionalMarginsInput = {
  autrePrixFixe: MarginType;
  prixCalage: MarginType;
  prixChangementPlaque: MarginType;
  prixCliche: MarginType;
  prixExemplaireSupp: MarginType;
  prixHorsCalage: MarginType;
  prixOutillage: MarginType;
  prixTraitementFichier: MarginType;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  deliveryDepartment?: Maybe<Scalars['String']>;
  firstAddress?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isShipping: Scalars['Boolean'];
  name: Scalars['String'];
  postBox?: Maybe<Scalars['String']>;
  secondAddress?: Maybe<Scalars['String']>;
  thirdAddress?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  deliveryDepartment?: InputMaybe<Scalars['String']>;
  firstAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isShipping: Scalars['Boolean'];
  name: Scalars['String'];
  postBox?: InputMaybe<Scalars['String']>;
  secondAddress?: InputMaybe<Scalars['String']>;
  thirdAddress?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type AttributConditionnement = {
  __typename?: 'AttributConditionnement';
  conditionnementList: Array<AttributConditionnementItem>;
  numberOfLots: Scalars['Float'];
  total?: Maybe<Scalars['Float']>;
};

export type AttributConditionnementInput = {
  conditionnementList: Array<AttributConditionnementItemInput>;
  numberOfLots: Scalars['Float'];
  total?: InputMaybe<Scalars['Float']>;
};

export type AttributConditionnementItem = {
  __typename?: 'AttributConditionnementItem';
  firstConditionnement?: Maybe<Conditionnement>;
  numberOfExLots?: Maybe<Scalars['Float']>;
  secondConditionnement?: Maybe<Conditionnement>;
  threeConditionnement?: Maybe<Conditionnement>;
};

export type AttributConditionnementItemInput = {
  firstConditionnement?: InputMaybe<ConditionnementInput>;
  numberOfExLots?: InputMaybe<Scalars['Float']>;
  secondConditionnement?: InputMaybe<ConditionnementInput>;
  threeConditionnement?: InputMaybe<ConditionnementInput>;
};

export type Attribute = {
  __typename?: 'Attribute';
  clientQuoteItemKey?: Maybe<ClientQuoteItemKey>;
  clientQuoteKey?: Maybe<ClientQuoteKey>;
  familiesGroup?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  index?: Maybe<Scalars['Float']>;
  isChecked?: Maybe<Scalars['Boolean']>;
  key?: Maybe<AttributeKey>;
  name: Scalars['String'];
  tableName?: Maybe<Scalars['String']>;
  type?: Maybe<ButtonType>;
};

export enum AttributeKey {
  Certification = 'certification',
  Chant = 'chant',
  Ciblage = 'ciblage',
  Classification = 'classification',
  Conditionnement = 'conditionnement',
  Couleur = 'couleur',
  Decoupe = 'decoupe',
  DescriptionIndication = 'descriptionIndication',
  DiametreCm = 'diametreCm',
  EnveloppeFenetre = 'enveloppeFenetre',
  EpaisseurMicrons = 'epaisseurMicrons',
  Faconnage = 'faconnage',
  FaconnageDeLivraison = 'faconnageDeLivraison',
  FinitionRecto = 'finitionRecto',
  FinitionVerso = 'finitionVerso',
  FondsPerdus = 'fondsPerdus',
  Format = 'format',
  Fourniture = 'fourniture',
  GrammageCouvertureGr = 'grammageCouvertureGr',
  GrammageGr = 'grammageGr',
  GrammageInterieurGr = 'grammageInterieurGr',
  ImpressionLogo = 'impressionLogo',
  ImpressionRectoVerso = 'impressionRectoVerso',
  LargeurCouvertureFormatFiniCm = 'largeurCouvertureFormatFiniCm',
  LargeurCouvertureFormatOuvertCm = 'largeurCouvertureFormatOuvertCm',
  LargeurCouvertureFormatPageCm = 'largeurCouvertureFormatPageCm',
  LargeurFormatFiniCm = 'largeurFormatFiniCm',
  LargeurFormatOuvertCm = 'largeurFormatOuvertCm',
  LargeurFormatPageCm = 'largeurFormatPageCm',
  LargeurInterieurEncartFormatFiniCm = 'largeurInterieurEncartFormatFiniCm',
  LargeurInterieurEncartFormatOuvertCm = 'largeurInterieurEncartFormatOuvertCm',
  LargeurInterieurEncartFormatPageCm = 'largeurInterieurEncartFormatPageCm',
  LargeurInterieurFormatFiniCm = 'largeurInterieurFormatFiniCm',
  LargeurInterieurFormatOuvertCm = 'largeurInterieurFormatOuvertCm',
  LargeurInterieurFormatPageCm = 'largeurInterieurFormatPageCm',
  LargeurPixel = 'largeurPixel',
  LargeurTailleFenetreCm = 'largeurTailleFenetreCm',
  LongueurCouvertureFormatFiniCm = 'longueurCouvertureFormatFiniCm',
  LongueurCouvertureFormatOuvertCm = 'longueurCouvertureFormatOuvertCm',
  LongueurCouvertureFormatPageCm = 'longueurCouvertureFormatPageCm',
  LongueurFormatFiniCm = 'longueurFormatFiniCm',
  LongueurFormatOuvertCm = 'longueurFormatOuvertCm',
  LongueurFormatPageCm = 'longueurFormatPageCm',
  LongueurInterieurEncartFormatFiniCm = 'longueurInterieurEncartFormatFiniCm',
  LongueurInterieurEncartFormatOuvertCm = 'longueurInterieurEncartFormatOuvertCm',
  LongueurInterieurEncartFormatPageCm = 'longueurInterieurEncartFormatPageCm',
  LongueurInterieurFormatFiniCm = 'longueurInterieurFormatFiniCm',
  LongueurInterieurFormatOuvertCm = 'longueurInterieurFormatOuvertCm',
  LongueurInterieurFormatPageCm = 'longueurInterieurFormatPageCm',
  LongueurPixel = 'longueurPixel',
  LongueurTailleFenetreCm = 'longueurTailleFenetreCm',
  Matiere = 'matiere',
  MatiereCouverture = 'matiereCouverture',
  MatiereInterieur = 'matiereInterieur',
  ModeleAchat = 'modeleAchat',
  NbCouleurCouvertureRecto = 'nbCouleurCouvertureRecto',
  NbCouleurCouvertureVerso = 'nbCouleurCouvertureVerso',
  NbCouleurInterieurEncartRecto = 'nbCouleurInterieurEncartRecto',
  NbCouleurInterieurEncartVerso = 'nbCouleurInterieurEncartVerso',
  NbCouleurInterieurRecto = 'nbCouleurInterieurRecto',
  NbCouleurInterieurVerso = 'nbCouleurInterieurVerso',
  NbCouleurRecto = 'nbCouleurRecto',
  NbCouleurVerso = 'nbCouleurVerso',
  ObjectifMarketing = 'objectifMarketing',
  OptionsFaconnage = 'optionsFaconnage',
  Orientation = 'orientation',
  OuvertureEnveloppe = 'ouvertureEnveloppe',
  PaginationCouverture = 'paginationCouverture',
  PaginationInterieur = 'paginationInterieur',
  PaginationInterieurEncart = 'paginationInterieurEncart',
  PaginationTotale = 'paginationTotale',
  PaysDeDiffusion = 'paysDeDiffusion',
  PrecisionsFaconnage = 'precisionsFaconnage',
  PrecisionsFourniture = 'precisionsFourniture',
  ProfondeurFormatFiniCm = 'profondeurFormatFiniCm',
  Repiquage = 'repiquage',
  RepiquageCoeur = 'repiquageCoeur',
  RepiquageDos = 'repiquageDOS',
  Surfacage = 'surfacage',
  SurfacageCouvertureRecto = 'surfacageCouvertureRecto',
  SurfacageCouvertureVerso = 'surfacageCouvertureVerso',
  SurfacageInterieurRecto = 'surfacageInterieurRecto',
  SurfacageInterieurVerso = 'surfacageInterieurVerso',
  Taille = 'taille',
  TauxSurfacage = 'tauxSurfacage',
  TypeEnvoi = 'typeEnvoi',
  TypeImpression = 'typeImpression',
  TypeMatiere = 'typeMatiere',
  TypeMatiereCouverture = 'typeMatiereCouverture',
  TypeMatiereInterieur = 'typeMatiereInterieur',
  TypePapier = 'typePapier',
  TypeSupport = 'typeSupport',
  ZoneTerritorial = 'zoneTerritorial',
}

export type AttributesFilterInput = {
  certification?: InputMaybe<Scalars['String']>;
  chant?: InputMaybe<Scalars['String']>;
  ciblage?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['String']>;
  codeAt?: InputMaybe<Scalars['String']>;
  conditionnement?: InputMaybe<AttributConditionnementInput>;
  couleur?: InputMaybe<Scalars['String']>;
  decoupe?: InputMaybe<Scalars['String']>;
  descriptionIndication?: InputMaybe<Scalars['String']>;
  diametreCm?: InputMaybe<Scalars['String']>;
  enveloppeFenetre?: InputMaybe<Scalars['String']>;
  epaisseurMicrons?: InputMaybe<Scalars['String']>;
  faconnage?: InputMaybe<Scalars['String']>;
  finitionRecto?: InputMaybe<Scalars['String']>;
  finitionVerso?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  fourniture?: InputMaybe<Scalars['String']>;
  grammageCouvertureGr?: InputMaybe<Scalars['String']>;
  grammageGr?: InputMaybe<Scalars['String']>;
  grammageInterieurGr?: InputMaybe<Scalars['String']>;
  impressionLogo?: InputMaybe<Scalars['String']>;
  impressionRectoVerso?: InputMaybe<Scalars['String']>;
  largeurCouvertureFormatFiniCm?: InputMaybe<Scalars['String']>;
  largeurCouvertureFormatOuvertCm?: InputMaybe<Scalars['String']>;
  largeurCouvertureFormatPageCm?: InputMaybe<Scalars['String']>;
  largeurFormatFiniCm?: InputMaybe<Scalars['String']>;
  largeurFormatOuvertCm?: InputMaybe<Scalars['String']>;
  largeurFormatPageCm?: InputMaybe<Scalars['String']>;
  largeurInterieurEncartFormatFiniCm?: InputMaybe<Scalars['String']>;
  largeurInterieurEncartFormatOuvertCm?: InputMaybe<Scalars['String']>;
  largeurInterieurEncartFormatPageCm?: InputMaybe<Scalars['String']>;
  largeurInterieurFormatFiniCm?: InputMaybe<Scalars['String']>;
  largeurInterieurFormatOuvertCm?: InputMaybe<Scalars['String']>;
  largeurInterieurFormatPageCm?: InputMaybe<Scalars['String']>;
  largeurPixel?: InputMaybe<Scalars['String']>;
  largeurTailleFenetreCm?: InputMaybe<Scalars['String']>;
  longueurCouvertureFormatFiniCm?: InputMaybe<Scalars['String']>;
  longueurCouvertureFormatOuvertCm?: InputMaybe<Scalars['String']>;
  longueurCouvertureFormatPageCm?: InputMaybe<Scalars['String']>;
  longueurFormatFiniCm?: InputMaybe<Scalars['String']>;
  longueurFormatOuvertCm?: InputMaybe<Scalars['String']>;
  longueurFormatPageCm?: InputMaybe<Scalars['String']>;
  longueurInterieurEncartFormatFiniCm?: InputMaybe<Scalars['String']>;
  longueurInterieurEncartFormatOuvertCm?: InputMaybe<Scalars['String']>;
  longueurInterieurEncartFormatPageCm?: InputMaybe<Scalars['String']>;
  longueurInterieurFormatFiniCm?: InputMaybe<Scalars['String']>;
  longueurInterieurFormatOuvertCm?: InputMaybe<Scalars['String']>;
  longueurInterieurFormatPageCm?: InputMaybe<Scalars['String']>;
  longueurPixel?: InputMaybe<Scalars['String']>;
  longueurTailleFenetreCm?: InputMaybe<Scalars['String']>;
  matiere?: InputMaybe<Scalars['String']>;
  matiereCouverture?: InputMaybe<Scalars['String']>;
  matiereInterieur?: InputMaybe<Scalars['String']>;
  modeleAchat?: InputMaybe<Scalars['String']>;
  nbCouleurCouvertureRecto?: InputMaybe<Scalars['String']>;
  nbCouleurCouvertureVerso?: InputMaybe<Scalars['String']>;
  nbCouleurInterieurEncartRecto?: InputMaybe<Scalars['String']>;
  nbCouleurInterieurEncartVerso?: InputMaybe<Scalars['String']>;
  nbCouleurInterieurRecto?: InputMaybe<Scalars['String']>;
  nbCouleurInterieurVerso?: InputMaybe<Scalars['String']>;
  nbCouleurRecto?: InputMaybe<Scalars['String']>;
  nbCouleurVerso?: InputMaybe<Scalars['String']>;
  objectifMarketing?: InputMaybe<Scalars['String']>;
  optionsFaconnage?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<Scalars['String']>;
  ouvertureEnveloppe?: InputMaybe<Scalars['String']>;
  paginationCouverture?: InputMaybe<Scalars['String']>;
  paginationInterieur?: InputMaybe<Scalars['String']>;
  paginationInterieurEncart?: InputMaybe<Scalars['String']>;
  paginationTotale?: InputMaybe<Scalars['String']>;
  paysDeDiffusion?: InputMaybe<Scalars['String']>;
  precisionsFaconnage?: InputMaybe<Scalars['String']>;
  precisionsFourniture?: InputMaybe<Scalars['String']>;
  profondeurFormatFiniCm?: InputMaybe<Scalars['String']>;
  repiquage?: InputMaybe<Scalars['String']>;
  repiquageCoeur?: InputMaybe<Scalars['String']>;
  repiquageDOS?: InputMaybe<Scalars['String']>;
  surfacage?: InputMaybe<Scalars['String']>;
  surfacageCouvertureRecto?: InputMaybe<Scalars['String']>;
  surfacageCouvertureVerso?: InputMaybe<Scalars['String']>;
  surfacageInterieurRecto?: InputMaybe<Scalars['String']>;
  surfacageInterieurVerso?: InputMaybe<Scalars['String']>;
  taille?: InputMaybe<Scalars['String']>;
  tauxSurfacage?: InputMaybe<Scalars['String']>;
  typeEnvoi?: InputMaybe<Scalars['String']>;
  typeImpression?: InputMaybe<Scalars['String']>;
  typeMatiere?: InputMaybe<Scalars['String']>;
  typeMatiereCouverture?: InputMaybe<Scalars['String']>;
  typeMatiereInterieur?: InputMaybe<Scalars['String']>;
  typePapier?: InputMaybe<Scalars['String']>;
  typeSupport?: InputMaybe<Scalars['String']>;
  zoneTerritorial?: InputMaybe<Scalars['String']>;
};

export type Auth = {
  __typename?: 'Auth';
  accessToken: Scalars['String'];
  user: User;
};

export type BestSellingOrders = {
  __typename?: 'BestSellingOrders';
  onePacDocumentId: Scalars['String'];
  onePacDocumentIdCount: Scalars['Float'];
  productSellingPriceHtMin?: Maybe<Scalars['Float']>;
};

export type BigShopConfig = {
  __typename?: 'BigShopConfig';
  callQuantity?: Maybe<Scalars['Float']>;
  category?: Maybe<BigShopConfigCategory>;
  categoryIcon?: Maybe<BigShopConfigCategoryIcon>;
  predefinedQuantity1?: Maybe<Scalars['Float']>;
  predefinedQuantity2?: Maybe<Scalars['Float']>;
  predefinedQuantity3?: Maybe<Scalars['Float']>;
  predefinedQuantity4?: Maybe<Scalars['Float']>;
  predefinedQuantity5?: Maybe<Scalars['Float']>;
  subCategory?: Maybe<BigShopConfigSubCategory>;
  supportId?: Maybe<Scalars['String']>;
};

export enum BigShopConfigCategory {
  AdvertisingItems = 'AdvertisingItems',
  AdvertisingPrints = 'AdvertisingPrints',
  Digital = 'Digital',
  SignagePos = 'SignagePos',
  StationeryShop = 'StationeryShop',
}

export enum BigShopConfigCategoryIcon {
  FormatShapes = 'FormatShapes',
  MenuBook = 'MenuBook',
  Phonelink = 'Phonelink',
  Style = 'Style',
  Tapas = 'Tapas',
}

export enum BigShopConfigSubCategory {
  AdvertisingPrints = 'AdvertisingPrints',
  Correspondance = 'Correspondance',
  Digital = 'Digital',
  Display = 'Display',
  Goodies = 'Goodies',
  Office = 'Office',
  PointOfSaleAdvertising = 'PointOfSaleAdvertising',
  Restoration = 'Restoration',
  SalonAndEvent = 'SalonAndEvent',
}

export type BigShopConfigUpsertInput = {
  callQuantity?: InputMaybe<Scalars['Float']>;
  category?: InputMaybe<BigShopConfigCategory>;
  categoryIcon?: InputMaybe<BigShopConfigCategoryIcon>;
  predefinedQuantity1?: InputMaybe<Scalars['Float']>;
  predefinedQuantity2?: InputMaybe<Scalars['Float']>;
  predefinedQuantity3?: InputMaybe<Scalars['Float']>;
  predefinedQuantity4?: InputMaybe<Scalars['Float']>;
  predefinedQuantity5?: InputMaybe<Scalars['Float']>;
  subCategory?: InputMaybe<BigShopConfigSubCategory>;
  supportId: Scalars['String'];
};

export enum BillingCadence {
  Daily = 'Daily',
  Monthly = 'Monthly',
  ToOrder = 'ToOrder',
  Weekly = 'Weekly',
}

export enum BoxShippingMode {
  BeforeNine = 'BeforeNine',
  BeforeThirteen = 'BeforeThirteen',
}

export type BoxesInfo = {
  __typename?: 'BoxesInfo';
  boxWeight: Scalars['Float'];
  boxesNumber: Scalars['Float'];
};

export enum ButtonType {
  Checkbox = 'Checkbox',
  Radio = 'Radio',
}

export type CharacteristicsQuoteAttribute = {
  __typename?: 'CharacteristicsQuoteAttribute';
  attributeId: Scalars['String'];
  clientQuoteItemKey?: Maybe<ClientQuoteItemKey>;
  clientQuoteKey?: Maybe<ClientQuoteKey>;
  isChecked: Scalars['Boolean'];
  key?: Maybe<AttributeKey>;
  name: Scalars['String'];
  type: ButtonType;
};

export enum ClariPrintCorrespondence {
  Brouchures = 'Brouchures',
  ChemiseRabat = 'ChemiseRabat',
  Depliants = 'Depliants',
  Enveloppe = 'Enveloppe',
  Feuillet = 'Feuillet',
}

export type Client = {
  __typename?: 'Client';
  billingAddress?: Maybe<Address>;
  billingCadence?: Maybe<BillingCadence>;
  clientAccountingAccount?: Maybe<Scalars['String']>;
  clientAnalyticCode?: Maybe<Scalars['String']>;
  clientCode?: Maybe<Scalars['String']>;
  clientContact?: Maybe<Array<Contact>>;
  clientPrices?: Maybe<Array<ClientPrice>>;
  clientShippingAddress: Array<ClientShippingAddress>;
  clientsSupports: Array<ClientSupport>;
  commercialId?: Maybe<Scalars['String']>;
  companyBase?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  defaultBilling?: Maybe<EntityType>;
  defaultCommercialProductMargin?: Maybe<Scalars['Float']>;
  defaultCommercialShippingMargin?: Maybe<Scalars['Float']>;
  elpevSite?: Maybe<ElpevSite>;
  fileCode?: Maybe<Scalars['String']>;
  groupingCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActif: Scalars['Boolean'];
  isGroupedBilling?: Maybe<Scalars['Boolean']>;
  isHorsRFA?: Maybe<Scalars['Boolean']>;
  isProspect: Scalars['Boolean'];
  isRoot: Scalars['Boolean'];
  logo: Scalars['String'];
  mainRfaRate?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parentClientId?: Maybe<Scalars['String']>;
  paymentDeadline?: Maybe<Scalars['String']>;
  possibleBillings: Array<EntityType>;
  possibleBillingsClients?: Maybe<Array<Client>>;
  projectManagerId?: Maybe<Scalars['String']>;
  rcsNumber?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  regions?: Maybe<Array<ClientRegion>>;
  responsibleId?: Maybe<Scalars['String']>;
  rfaComment?: Maybe<Scalars['String']>;
  rootClient?: Maybe<Client>;
  rootClientId?: Maybe<Scalars['String']>;
  siretNumber?: Maybe<Scalars['String']>;
  structures: Array<ClientStructure>;
  territorialArea?: Maybe<TerritorialArea>;
  tradeNameInvoice?: Maybe<Scalars['String']>;
  translation?: Maybe<ClientQuoteTranslate>;
  transplantName?: Maybe<Scalars['String']>;
  type: EntityType;
  typologies?: Maybe<Array<ClientTypology>>;
  typologyId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  users?: Maybe<UserDetails>;
  vatNumber?: Maybe<Scalars['String']>;
  vatType?: Maybe<Scalars['Boolean']>;
};

export type ClientChildren = {
  __typename?: 'ClientChildren';
  children: Array<ClientChildren>;
  fileCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActif: Scalars['Boolean'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type ClientFilterInput = {
  AND?: InputMaybe<Array<ClientFilterInput>>;
  NOT?: InputMaybe<Array<ClientFilterInput>>;
  OR?: InputMaybe<Array<ClientFilterInput>>;
  comment?: InputMaybe<Scalars['String']>;
  companyBase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isActif?: InputMaybe<Scalars['Boolean']>;
  isProspect?: InputMaybe<Scalars['Boolean']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parentClientId?: InputMaybe<Scalars['String']>;
  rcsNumber?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<StringFilter>;
  rootClientId?: InputMaybe<Scalars['String']>;
  salesperson?: InputMaybe<Scalars['String']>;
  siretNumber?: InputMaybe<Scalars['String']>;
  tradeNameInvoice?: InputMaybe<Scalars['String']>;
  transplantName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EntityTypeFilter>;
  typologyId?: InputMaybe<StringFilter>;
  vatNumber?: InputMaybe<Scalars['String']>;
  vatType?: InputMaybe<Scalars['Boolean']>;
};

export type ClientFilterInputByCompanyBaseInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstAddress?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  storeCode?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type ClientInsertInput = {
  billingCadence?: InputMaybe<BillingCadence>;
  companyBase?: InputMaybe<Scalars['String']>;
  defaultBilling?: InputMaybe<EntityType>;
  defaultCommercialProductMargin?: InputMaybe<Scalars['Float']>;
  isActif?: InputMaybe<Scalars['Boolean']>;
  isProspect?: InputMaybe<Scalars['Boolean']>;
  isRoot: Scalars['Boolean'];
  name: Scalars['String'];
  parentClientId?: InputMaybe<Scalars['String']>;
  possibleBillings?: InputMaybe<Array<EntityType>>;
  regionId?: InputMaybe<Scalars['String']>;
  rootClientId?: InputMaybe<Scalars['String']>;
  territorialArea?: InputMaybe<TerritorialArea>;
  tradeNameInvoice?: InputMaybe<Scalars['String']>;
  transplantName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EntityType>;
  typologyId?: InputMaybe<Scalars['String']>;
};

export type ClientPrice = {
  __typename?: 'ClientPrice';
  additionalMargins: AdditionalMargins;
  bestDeliveryParcelPurchasePrice: Scalars['Float'];
  buyingPriceWithAmalgam: Scalars['Float'];
  client?: Maybe<Client>;
  clientId: Scalars['String'];
  corsicaDeliveryExtra: Scalars['Float'];
  cost: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  defaultCommercialProductMargin: Scalars['Float'];
  defaultCommercialShippingMargin?: Maybe<Scalars['Float']>;
  deliveryDepartment?: Maybe<Scalars['String']>;
  deliveryPackagePurchasePriceInfo?: Maybe<Scalars['String']>;
  deliveryParcel: BoxShippingMode;
  departingDepartment?: Maybe<Scalars['String']>;
  franco: Scalars['Boolean'];
  isFinalDistribution: Scalars['Boolean'];
  isIdentical: Scalars['Boolean'];
  isMechanizable: Scalars['Boolean'];
  isPrepress: Scalars['Boolean'];
  isSpecificShipment?: Maybe<Scalars['Boolean']>;
  isWithRfaClient: Scalars['Boolean'];
  mainRfaRate?: Maybe<Scalars['Float']>;
  margins: Margins;
  maxBoxWeight: Scalars['Float'];
  nbShippingPoints: Scalars['Float'];
  numberFileProcessing: Scalars['Float'];
  numberPlateChanges: Scalars['Float'];
  numberShots: Scalars['Float'];
  numberVersion: Scalars['Float'];
  /** @deprecated unused, to delete in all queries please! */
  palettes?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  productId: Scalars['String'];
  productName: Scalars['String'];
  productPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
  productPurchasePriceExcludingVATWithRFA?: Maybe<Scalars['Float']>;
  productSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
  productSalesPriceExcludingVATRectified?: Maybe<Scalars['Float']>;
  productSalesPriceExcludingVATWithRfa: Scalars['Float'];
  productTotalWeight: Scalars['Float'];
  purchasePriceExcludingTax: Margins;
  quantities: Array<Scalars['Float']>;
  quantity: Scalars['Float'];
  rfaClientEuro: Scalars['Float'];
  salePriceExcludingVAT?: Maybe<Scalars['Float']>;
  sellingPriceMargin?: Maybe<Scalars['Float']>;
  sellingPriceMarginRate: Scalars['Float'];
  sellingPriceWithMargin: Margins;
  specificEmail?: Maybe<Scalars['String']>;
  specificSupplier?: Maybe<Supplier>;
  specificSupplierId?: Maybe<Scalars['String']>;
  status: ClientPriceStatus;
  /** @deprecated unused, to delete in all queries please! */
  tax?: Maybe<Scalars['Float']>;
  tools: Scalars['Boolean'];
  totalProductSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
  transportMoment: TransportMoment;
  transportPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
  transportPurchasePriceHt: Scalars['Float'];
  transportPurchasePriceHtWithRfa: Scalars['Float'];
  transportRfa: Scalars['Float'];
  transportSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
  transportSellingPriceByDefaultHt: Scalars['Float'];
  transportSellingPriceHt: Scalars['Float'];
  transportSellingPriceHtAdditionalMargin: MarginType;
  transportSellingPriceHtWithClientRfa?: Maybe<Scalars['Float']>;
  transportSellingPriceHtWithClientRfaRectified?: Maybe<Scalars['Float']>;
  transportSellingPriceMargin: Scalars['Float'];
  transportType: TransportType;
  transporter?: Maybe<TransporterIdentity>;
  updatedAt: Scalars['DateTime'];
  /** @deprecated to be deleted and replaced by status.ValidatedByCustomerQuote  */
  validatedByQuote?: Maybe<Scalars['Float']>;
  validatedQuoteDate?: Maybe<Scalars['DateTime']>;
};

export type ClientPriceInput = {
  additionalMargins: AdditionalMarginsInput;
  bestDeliveryParcelPurchasePrice: Scalars['Float'];
  buyingPriceWithAmalgam: Scalars['Float'];
  clientId: Scalars['String'];
  clientPriceUniqInput?: InputMaybe<ClientPriceUniqInput>;
  corsicaDeliveryExtra: Scalars['Float'];
  cost: Scalars['Float'];
  defaultCommercialProductMargin?: InputMaybe<Scalars['Float']>;
  defaultCommercialShippingMargin?: InputMaybe<Scalars['Float']>;
  deliveryDepartment?: InputMaybe<Scalars['String']>;
  deliveryPackagePurchasePriceInfo?: InputMaybe<Scalars['String']>;
  deliveryParcel: BoxShippingMode;
  departingDepartment?: InputMaybe<Scalars['String']>;
  distributions?: InputMaybe<Array<DistributionInput>>;
  franco: Scalars['Boolean'];
  isFinalDistribution: Scalars['Boolean'];
  isIdentical: Scalars['Boolean'];
  isMechanizable: Scalars['Boolean'];
  isPrepress: Scalars['Boolean'];
  isSpecificShipment?: InputMaybe<Scalars['Boolean']>;
  isWithRfaClient: Scalars['Boolean'];
  mainRfaRate?: InputMaybe<Scalars['Float']>;
  margins: MarginsInput;
  maxBoxWeight: Scalars['Float'];
  nbShippingPoints?: InputMaybe<Scalars['Float']>;
  numberFileProcessing: Scalars['Float'];
  numberPlateChanges: Scalars['Float'];
  numberShots: Scalars['Float'];
  numberVersion: Scalars['Float'];
  palettes?: InputMaybe<Scalars['Float']>;
  productId: Scalars['String'];
  productName: Scalars['String'];
  productPurchasePriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  productPurchasePriceExcludingVATWithRFA?: InputMaybe<Scalars['Float']>;
  productSalesPriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  productSalesPriceExcludingVATRectified?: InputMaybe<Scalars['Float']>;
  productSalesPriceExcludingVATWithRfa: Scalars['Float'];
  productTotalWeight: Scalars['Float'];
  purchasePriceExcludingTax: MarginsInput;
  quantities: Array<Scalars['Float']>;
  quantity: Scalars['Float'];
  rfaClientEuro: Scalars['Float'];
  sellingPriceMargin?: InputMaybe<Scalars['Float']>;
  sellingPriceMarginRate: Scalars['Float'];
  sellingPriceWithMargin: MarginsInput;
  specificEmail?: InputMaybe<Scalars['String']>;
  specificSupplierId?: InputMaybe<Scalars['String']>;
  status: ClientPriceStatus;
  tax?: InputMaybe<Scalars['Float']>;
  tools: Scalars['Boolean'];
  totalProductSalesPriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  transportMoment: TransportMoment;
  transportPurchasePriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  transportPurchasePriceHt: Scalars['Float'];
  transportPurchasePriceHtWithRfa: Scalars['Float'];
  transportRfa: Scalars['Float'];
  transportSalesPriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  transportSellingPriceByDefaultHt: Scalars['Float'];
  transportSellingPriceHt: Scalars['Float'];
  transportSellingPriceHtAdditionalMargin: MarginType;
  transportSellingPriceHtWithClientRfa?: InputMaybe<Scalars['Float']>;
  transportSellingPriceHtWithClientRfaRectified?: InputMaybe<Scalars['Float']>;
  transportSellingPriceMargin: Scalars['Float'];
  transportType: TransportType;
  transporterId?: InputMaybe<Scalars['String']>;
  validatedByQuote?: InputMaybe<Scalars['Float']>;
  validatedQuoteDate?: InputMaybe<Scalars['DateTime']>;
};

export enum ClientPriceStatus {
  InProgress = 'InProgress',
  Obsolete = 'Obsolete',
  ValidatedByCustomerQuote = 'ValidatedByCustomerQuote',
  WaitingByValidationQuote = 'WaitingByValidationQuote',
}

export type ClientPriceUniqInput = {
  clientId: Scalars['String'];
  numberVersion: Scalars['Float'];
  productId: Scalars['String'];
  quantity: Scalars['Float'];
};

export type ClientPricesDistributions = {
  __typename?: 'ClientPricesDistributions';
  productId: Scalars['String'];
  productPurchasePriceExcludingVAT: Scalars['Float'];
  productSalesPriceExcludingVAT: Scalars['Float'];
  sellingPriceMargin: Scalars['Float'];
  transportPurchasePriceExcludingVAT: Scalars['Float'];
  transportSalesPriceExcludingVAT: Scalars['Float'];
};

export type ClientPricesDistributionsInput = {
  clientId: Scalars['String'];
  distributions: Array<ClientQuoteDistributionInput>;
  numberVersion: Scalars['Float'];
};

export type ClientPricesFiltersInput = {
  attributes?: InputMaybe<AttributesFilterInput>;
  clientId: Scalars['String'];
  productId?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<ClientPriceStatus>;
};

export type ClientQuote = {
  __typename?: 'ClientQuote';
  additionalCostsIncurred: Scalars['Float'];
  billingAddress?: Maybe<ClientQuoteBillingAddress>;
  client: Client;
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  clientQuoteDistributions?: Maybe<Array<ClientQuoteDistribution>>;
  clientQuoteItem?: Maybe<Array<ClientQuoteItem>>;
  clientReference?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  cost: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  deliveryDate?: Maybe<Scalars['DateTime']>;
  estimatedCiteoTax: Scalars['Float'];
  fileDeliveryDate?: Maybe<Scalars['DateTime']>;
  fileReferenceId?: Maybe<Scalars['String']>;
  fileReferences: FileReference;
  id: Scalars['String'];
  isDistribution: Scalars['Boolean'];
  margin: Scalars['Float'];
  marginRate: Scalars['Float'];
  nbShippingPoints: Scalars['Float'];
  operationName?: Maybe<Scalars['String']>;
  quoteRequestedBy?: Maybe<Scalars['String']>;
  status: ClientQuoteStatus;
  totalPurchasePriceExcludingVAT: Scalars['Float'];
  totalSalesPriceExcludingVAT: Scalars['Float'];
  totalSalesPriceVAT: Scalars['Float'];
  type: QuoteType;
  updatedAt: Scalars['DateTime'];
  vat: ClientQuoteVat;
  vatAmount: Scalars['Float'];
  vatRate: Scalars['Float'];
  version: Scalars['Float'];
};

export type ClientQuoteBillingAddress = {
  __typename?: 'ClientQuoteBillingAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstAddress?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  secondAddress?: Maybe<Scalars['String']>;
  thirdAddress?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type ClientQuoteBillingAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstAddress?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  secondAddress?: InputMaybe<Scalars['String']>;
  thirdAddress?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type ClientQuoteConfigAttributes = {
  attributeId: Scalars['String'];
  clientId: Scalars['String'];
};

export type ClientQuoteConfigAttributesCreateInput = {
  data: Array<ClientQuoteConfigAttributes>;
  quoteClient?: InputMaybe<QuoteClient>;
};

export type ClientQuoteConfigAttributesCreateModel = {
  __typename?: 'ClientQuoteConfigAttributesCreateModel';
  quoteClient: QuoteClientModel;
  quoteConfig: Array<ClientQuoteConfigAttributesModel>;
};

export type ClientQuoteConfigAttributesModel = {
  __typename?: 'ClientQuoteConfigAttributesModel';
  attributeId: Scalars['String'];
  clientId: Scalars['String'];
};

export type ClientQuoteDistribution = {
  __typename?: 'ClientQuoteDistribution';
  clientQuoteId: Scalars['String'];
  clientShippingAddress?: Maybe<ClientShippingAddress>;
  clientShippingAddressId: Scalars['String'];
  deliveryParcel?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  productId: Scalars['String'];
  productName: Scalars['String'];
  quantity: Scalars['Float'];
  store?: Maybe<Client>;
  storeId: Scalars['String'];
  transportMoment?: Maybe<Scalars['String']>;
};

export type ClientQuoteDistributionInput = {
  clientQuoteId?: InputMaybe<Scalars['String']>;
  clientShippingAddressId: Scalars['String'];
  deliveryParcel?: InputMaybe<BoxShippingMode>;
  productId: Scalars['String'];
  productName: Scalars['String'];
  quantity: Scalars['Float'];
  storeId: Scalars['String'];
  transportMoment?: InputMaybe<TransportMoment>;
};

export enum ClientQuoteFileType {
  Pdf = 'pdf',
  Xlsx = 'xlsx',
}

export type ClientQuoteFilterInput = {
  fileReferenceId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ClientQuoteStatus>;
};

export type ClientQuoteGenerateFileInput = {
  dataQuote: Scalars['String'];
  type: ClientQuoteFileType;
};

export type ClientQuoteItem = {
  __typename?: 'ClientQuoteItem';
  clientQuoteId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  corsicaDeliveryExtra: Scalars['Float'];
  cost: Scalars['Float'];
  deliveryParcel: BoxShippingMode;
  detailPrepressePDF?: Maybe<Scalars['String']>;
  estimatedCiteoTax?: Maybe<Scalars['Float']>;
  extraUnitsCost?: Maybe<Scalars['Float']>;
  franco: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
  isFinalDistribution: Scalars['Boolean'];
  isMechanizable: Scalars['Boolean'];
  montantRFA?: Maybe<Scalars['Float']>;
  montantTVA?: Maybe<Scalars['Float']>;
  montantTaxeCiteo?: Maybe<Scalars['Float']>;
  nbDeChgtDePlaque?: Maybe<Scalars['Float']>;
  nbDeCliche?: Maybe<Scalars['Float']>;
  nbDePointDeLivraison?: Maybe<Scalars['Float']>;
  nbShippingPoints: Scalars['Float'];
  numberFileProcessing: Scalars['Float'];
  numberPlateChanges: Scalars['Float'];
  numberShots: Scalars['Float'];
  numberVersion: Scalars['Float'];
  prepressPriceExcludingVat?: Maybe<Scalars['Float']>;
  prepressSalesPriceExcludingVat?: Maybe<Scalars['Float']>;
  prepresseInclus?: Maybe<Scalars['String']>;
  prepresseNonApplicable?: Maybe<Scalars['String']>;
  prepresseNonInclus?: Maybe<Scalars['String']>;
  prixHT?: Maybe<Scalars['Float']>;
  prixPrepresseHT?: Maybe<Scalars['Float']>;
  prixTTC?: Maybe<Scalars['Float']>;
  product: Product;
  productCommentaires?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  productName: Scalars['String'];
  productPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
  productSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
  productTotalWeight: Scalars['Float'];
  quantity: Scalars['Float'];
  referenceGID?: Maybe<Scalars['String']>;
  rfaClientEuro: Scalars['Float'];
  salePrice?: Maybe<Scalars['Float']>;
  sellingPriceIncludingTax: Scalars['Float'];
  sellingPriceMargin: Scalars['Float'];
  sellingPriceWithMargin: Margins;
  sousReserveDeRepartitionDefinitve?: Maybe<Scalars['Boolean']>;
  tauxTVA?: Maybe<Scalars['Float']>;
  tools: Scalars['Boolean'];
  totalProductSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
  transportInclus?: Maybe<Scalars['String']>;
  transportMoment: TransportMoment;
  transportNonInclus?: Maybe<Scalars['String']>;
  transportPurchasePriceExcludingVAT?: Maybe<Scalars['Float']>;
  transportPurchasePriceHt?: Maybe<Scalars['Float']>;
  transportPurchasePriceHtWithRfa?: Maybe<Scalars['Float']>;
  transportRfa?: Maybe<Scalars['Float']>;
  transportSalesPriceExcludingVAT?: Maybe<Scalars['Float']>;
  transportSellingPriceByDefaultHt?: Maybe<Scalars['Float']>;
  transportSellingPriceHt?: Maybe<Scalars['Float']>;
  transportSellingPriceHtAdditionalMargin: MarginType;
  transportSellingPriceHtWithClientRfa?: Maybe<Scalars['Float']>;
  transportSellingPriceHtWithClientRfaRectified?: Maybe<Scalars['Float']>;
  transportSellingPriceMargin?: Maybe<Scalars['Float']>;
  transportType: TransportType;
  transporterId?: Maybe<Scalars['String']>;
  vatAmount?: Maybe<Scalars['Float']>;
};

export type ClientQuoteItemInsertInput = {
  comment?: InputMaybe<Scalars['String']>;
  corsicaDeliveryExtra: Scalars['Float'];
  cost?: InputMaybe<Scalars['Float']>;
  deliveryParcel: BoxShippingMode;
  estimatedCiteoTax?: InputMaybe<Scalars['Float']>;
  extraUnitsCost?: InputMaybe<Scalars['Float']>;
  franco: Scalars['Boolean'];
  id?: InputMaybe<Scalars['String']>;
  isFinalDistribution: Scalars['Boolean'];
  isMechanizable: Scalars['Boolean'];
  nbShippingPoints: Scalars['Float'];
  numberFileProcessing: Scalars['Float'];
  numberPlateChanges: Scalars['Float'];
  numberShots: Scalars['Float'];
  numberVersion: Scalars['Float'];
  prepressPriceExcludingVat?: InputMaybe<Scalars['Float']>;
  prepressSalesPriceExcludingVat?: InputMaybe<Scalars['Float']>;
  productId: Scalars['String'];
  productName: Scalars['String'];
  productPurchasePriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  productSalesPriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  productTotalWeight: Scalars['Float'];
  quantity: Scalars['Float'];
  rfaClientEuro: Scalars['Float'];
  salePrice?: InputMaybe<Scalars['Float']>;
  sellingPriceIncludingTax: Scalars['Float'];
  sellingPriceMargin: Scalars['Float'];
  sellingPriceWithMargin: MarginsInput;
  tools: Scalars['Boolean'];
  totalProductSalesPriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  totalSalesPriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  transportMoment: TransportMoment;
  transportPurchasePriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  transportPurchasePriceHt?: InputMaybe<Scalars['Float']>;
  transportPurchasePriceHtWithRfa?: InputMaybe<Scalars['Float']>;
  transportRfa?: InputMaybe<Scalars['Float']>;
  transportSalesPriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  transportSellingPriceByDefaultHt?: InputMaybe<Scalars['Float']>;
  transportSellingPriceHt?: InputMaybe<Scalars['Float']>;
  transportSellingPriceHtAdditionalMargin: MarginType;
  transportSellingPriceHtWithClientRfa?: InputMaybe<Scalars['Float']>;
  transportSellingPriceHtWithClientRfaRectified?: InputMaybe<Scalars['Float']>;
  transportSellingPriceMargin?: InputMaybe<Scalars['Float']>;
  transportType: TransportType;
  transporterId?: InputMaybe<Scalars['String']>;
  vatAmount?: InputMaybe<Scalars['Float']>;
};

export enum ClientQuoteItemKey {
  AutrePrixFixeHt = 'autrePrixFixeHT',
  Comment = 'comment',
  Cost = 'cost',
  DetailPrepressePdf = 'detailPrepressePDF',
  ExtraUnitsCost = 'extraUnitsCost',
  MontantRfa = 'montantRFA',
  MontantTva = 'montantTVA',
  MontantTaxeCiteo = 'montantTaxeCiteo',
  NbDeChgtDePlaque = 'nbDeChgtDePlaque',
  NbDeCliche = 'nbDeCliche',
  NbDePointDeLivraison = 'nbDePointDeLivraison',
  NumberFileProcessing = 'numberFileProcessing',
  PrepresseInclus = 'prepresseInclus',
  PrepresseNonApplicable = 'prepresseNonApplicable',
  PrepresseNonInclus = 'prepresseNonInclus',
  PrixCalageHt = 'prixCalageHT',
  PrixCentMoinsHt = 'prixCentMoinsHT',
  PrixCentPlusHt = 'prixCentPlusHT',
  PrixChgtParPlaqueHt = 'prixChgtParPlaqueHT',
  PrixClicheHt = 'prixClicheHT',
  PrixExSupHt = 'prixExSupHT',
  PrixHt = 'prixHT',
  PrixHorsCalageHt = 'prixHorsCalageHT',
  PrixMilleMoinsHt = 'prixMilleMoinsHT',
  PrixMillePlusHt = 'prixMillePlusHT',
  PrixOutillageHt = 'prixOutillageHT',
  PrixPrepresseHt = 'prixPrepresseHT',
  PrixTtc = 'prixTTC',
  PrixTraitementFichierHt = 'prixTraitementFichierHT',
  ProductCommentaires = 'productCommentaires',
  ProductName = 'productName',
  ProductPurchasePriceExcludingVat = 'productPurchasePriceExcludingVAT',
  Quantity = 'quantity',
  ReferenceGid = 'referenceGID',
  SousReserveDeRepartitionDefinitve = 'sousReserveDeRepartitionDefinitve',
  TauxTva = 'tauxTVA',
  Tools = 'tools',
  TransportInclus = 'transportInclus',
  TransportNonInclus = 'transportNonInclus',
  TransportSalesPriceExcludingVat = 'transportSalesPriceExcludingVAT',
  VersionNumber = 'versionNumber',
  Weight = 'weight',
}

export enum ClientQuoteKey {
  AdditionalCostsIncurred = 'additionalCostsIncurred',
  Cost = 'cost',
  EstimatedCiteoTax = 'estimatedCiteoTax',
  TotalSalesPriceVat = 'totalSalesPriceVAT',
  VatAmount = 'vatAmount',
  VatRate = 'vatRate',
}

export enum ClientQuoteTranslate {
  French = 'French',
}

export type ClientQuoteUpsertInput = {
  billingAddress?: InputMaybe<ClientQuoteBillingAddressInput>;
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  clientQuoteDistributions?: InputMaybe<Array<ClientQuoteDistributionInput>>;
  clientQuoteItem?: InputMaybe<Array<ClientQuoteItemInsertInput>>;
  clientQuoteItemIdsToDelete?: InputMaybe<Array<Scalars['String']>>;
  clientReference?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']>;
  estimatedCiteoTax: Scalars['Float'];
  extraUnitsCost?: InputMaybe<Scalars['Float']>;
  fileDeliveryDate?: InputMaybe<Scalars['DateTime']>;
  fileReferenceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isDistribution: Scalars['Boolean'];
  margin: Scalars['Float'];
  marginRate: Scalars['Float'];
  nbShippingPoints: Scalars['Float'];
  operationName?: InputMaybe<Scalars['String']>;
  quoteRequestedBy?: InputMaybe<Scalars['String']>;
  status: ClientQuoteStatus;
  tools?: InputMaybe<Scalars['String']>;
  totalPurchasePriceExcludingVAT: Scalars['Float'];
  totalSalesPriceExcludingVAT: Scalars['Float'];
  totalSalesPriceVAT: Scalars['Float'];
  type: QuoteType;
  vat: ClientQuoteVat;
  vatAmount: Scalars['Float'];
  vatRate: Scalars['Float'];
  version: Scalars['Float'];
};

export enum ClientQuoteVat {
  Francaise = 'Francaise',
}

export type ClientRegion = {
  __typename?: 'ClientRegion';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ClientRegionInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClientShippingAddress = {
  __typename?: 'ClientShippingAddress';
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['String']>;
  boxShippingMode?: Maybe<BoxShippingMode>;
  clientId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  contact?: Maybe<ClientShippingAddressContact>;
  id: Scalars['String'];
  isMain: Scalars['Boolean'];
  maxBoxWeight?: Maybe<Scalars['Float']>;
  palletShippingMode?: Maybe<PalletShippingMode>;
  referenceId?: Maybe<Scalars['String']>;
  workingDays?: Maybe<Array<WorkingDay>>;
};

export type ClientShippingAddressByCompanyBase = {
  __typename?: 'ClientShippingAddressByCompanyBase';
  billingAddress?: Maybe<Address>;
  billingCadence?: Maybe<BillingCadence>;
  clientAccountingAccount?: Maybe<Scalars['String']>;
  clientAnalyticCode?: Maybe<Scalars['String']>;
  clientCode?: Maybe<Scalars['String']>;
  clientContact?: Maybe<Array<Contact>>;
  clientPrices?: Maybe<Array<ClientPrice>>;
  clientShippingAddress: Array<ClientShippingAddress>;
  clientsSupports: Array<ClientSupport>;
  commercialId?: Maybe<Scalars['String']>;
  companyBase?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  defaultBilling?: Maybe<EntityType>;
  defaultCommercialProductMargin?: Maybe<Scalars['Float']>;
  defaultCommercialShippingMargin?: Maybe<Scalars['Float']>;
  elpevSite?: Maybe<ElpevSite>;
  fileCode?: Maybe<Scalars['String']>;
  groupingCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActif: Scalars['Boolean'];
  isGroupedBilling?: Maybe<Scalars['Boolean']>;
  isHorsRFA?: Maybe<Scalars['Boolean']>;
  isProspect: Scalars['Boolean'];
  isRoot: Scalars['Boolean'];
  logo: Scalars['String'];
  mainRfaRate?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parentClientId?: Maybe<Scalars['String']>;
  paymentDeadline?: Maybe<Scalars['String']>;
  possibleBillings: Array<EntityType>;
  possibleBillingsClients?: Maybe<Array<Client>>;
  projectManagerId?: Maybe<Scalars['String']>;
  rcsNumber?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  regions?: Maybe<Array<ClientRegion>>;
  responsibleId?: Maybe<Scalars['String']>;
  rfaComment?: Maybe<Scalars['String']>;
  rootClient?: Maybe<Client>;
  rootClientId?: Maybe<Scalars['String']>;
  siretNumber?: Maybe<Scalars['String']>;
  structures: Array<ClientStructure>;
  territorialArea?: Maybe<TerritorialArea>;
  tradeNameInvoice?: Maybe<Scalars['String']>;
  translation?: Maybe<ClientQuoteTranslate>;
  transplantName?: Maybe<Scalars['String']>;
  type: EntityType;
  typologies?: Maybe<Array<ClientTypology>>;
  typologyId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  users?: Maybe<UserDetails>;
  vatNumber?: Maybe<Scalars['String']>;
  vatType?: Maybe<Scalars['Boolean']>;
};

export type ClientShippingAddressContact = {
  __typename?: 'ClientShippingAddressContact';
  directorName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  isNotificated?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  storeCode?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type ClientShippingAddressContactInput = {
  directorName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  isNotificated?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  storeCode?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type ClientShippingAddressInsertInput = {
  address: AddressInput;
  addressId?: InputMaybe<Scalars['String']>;
  boxShippingMode?: InputMaybe<BoxShippingMode>;
  clientId: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<ClientShippingAddressContactInput>;
  id?: InputMaybe<Scalars['String']>;
  isMain: Scalars['Boolean'];
  maxBoxWeight?: InputMaybe<Scalars['Float']>;
  palletShippingMode?: InputMaybe<PalletShippingMode>;
  referenceId?: InputMaybe<Scalars['String']>;
  workingDays?: InputMaybe<Array<WorkingDayInput>>;
};

export type ClientShippingAddressUpdateInput = {
  address?: InputMaybe<AddressInput>;
  addressId?: InputMaybe<Scalars['String']>;
  boxShippingMode?: InputMaybe<BoxShippingMode>;
  clientId: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<ClientShippingAddressContactInput>;
  id: Scalars['String'];
  isMain: Scalars['Boolean'];
  maxBoxWeight?: InputMaybe<Scalars['Float']>;
  palletShippingMode?: InputMaybe<PalletShippingMode>;
  referenceId?: InputMaybe<Scalars['String']>;
  workingDays?: InputMaybe<Array<WorkingDayInput>>;
};

export type ClientStructure = {
  __typename?: 'ClientStructure';
  isBillable: Scalars['Boolean'];
  level: Scalars['Float'];
  title: Scalars['String'];
  type: EntityType;
};

export type ClientStructureInput = {
  isBillable: Scalars['Boolean'];
  level: Scalars['Float'];
  title: Scalars['String'];
  type: EntityType;
};

export type ClientSupport = {
  __typename?: 'ClientSupport';
  margin: Scalars['Float'];
  marginType: MarginType;
  support: Support;
  supportId: Scalars['String'];
};

export type ClientSupportInput = {
  margin: Scalars['Float'];
  marginType: MarginType;
  supportId: Scalars['String'];
};

export type ClientTypology = {
  __typename?: 'ClientTypology';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ClientTypologyInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClientUpdateInput = {
  billingAddress?: InputMaybe<AddressInput>;
  billingCadence?: InputMaybe<BillingCadence>;
  clientAccountingAccount?: InputMaybe<Scalars['String']>;
  clientAnalyticCode?: InputMaybe<Scalars['String']>;
  clientCode?: InputMaybe<Scalars['String']>;
  clientContact?: InputMaybe<Array<ContactInput>>;
  clientShippingAddress?: InputMaybe<Array<ClientShippingAddressUpdateInput>>;
  clientsSupports?: InputMaybe<Array<ClientSupportInput>>;
  commercialId?: InputMaybe<Scalars['String']>;
  companyBase: Scalars['String'];
  defaultBilling?: InputMaybe<EntityType>;
  defaultCommercialProductMargin?: InputMaybe<Scalars['Float']>;
  defaultCommercialShippingMargin?: InputMaybe<Scalars['Float']>;
  fileCode?: InputMaybe<Scalars['String']>;
  groupingCode?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isActif: Scalars['Boolean'];
  isGroupedBilling?: InputMaybe<Scalars['Boolean']>;
  isHorsRFA?: InputMaybe<Scalars['Boolean']>;
  isProspect: Scalars['Boolean'];
  isRoot: Scalars['Boolean'];
  mainRfaRate?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  parentClientId?: InputMaybe<Scalars['String']>;
  paymentDeadline?: InputMaybe<Scalars['String']>;
  possibleBillings?: InputMaybe<Array<EntityType>>;
  projectManagerId?: InputMaybe<Scalars['String']>;
  rcsNumber?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['String']>;
  regions?: InputMaybe<Array<ClientRegionInput>>;
  responsibleId?: InputMaybe<Scalars['String']>;
  rfaComment?: InputMaybe<Scalars['String']>;
  rootClientId?: InputMaybe<Scalars['String']>;
  salesperson?: InputMaybe<Scalars['String']>;
  siretNumber?: InputMaybe<Scalars['String']>;
  structures?: InputMaybe<Array<ClientStructureInput>>;
  territorialArea?: InputMaybe<TerritorialArea>;
  tradeNameInvoice?: InputMaybe<Scalars['String']>;
  transplantName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EntityType>;
  typologies?: InputMaybe<Array<ClientTypologyInput>>;
  typologyId?: InputMaybe<Scalars['String']>;
  vatNumber?: InputMaybe<Scalars['String']>;
  vatType?: InputMaybe<Scalars['Boolean']>;
};

export type CockpitOrderUpdateInput = {
  followStatus: FollowOrderStatus;
  id: Scalars['String'];
  onepacUserId?: InputMaybe<Scalars['String']>;
  onepacUserName?: InputMaybe<Scalars['String']>;
  statusComment?: InputMaybe<Scalars['String']>;
};

export type CockpitOrderUpsertInput = {
  billingAddress?: InputMaybe<ClientQuoteBillingAddressInput>;
  childrenOrder?: InputMaybe<Array<CockpitOrderUpsertInput>>;
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  clientReference?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  followStatus?: InputMaybe<FollowOrderStatus>;
  id?: InputMaybe<Scalars['String']>;
  invoices?: InputMaybe<Array<InvoiceUpsertInput>>;
  marginRate?: InputMaybe<Scalars['Float']>;
  marginWithClientRfa?: InputMaybe<Scalars['Float']>;
  operationName?: InputMaybe<Scalars['String']>;
  orderItem: Array<OrderItemCockpitUpsertInput>;
  paymentType?: InputMaybe<OrderPaymentType>;
  realMargin?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<OrderStatus>;
};

export type CommercialInitials = {
  __typename?: 'CommercialInitials';
  fullName?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
};

export type Conditionnement = {
  __typename?: 'Conditionnement';
  conditionnement: Scalars['String'];
  labeling: Scalars['Boolean'];
  nbExConditionnement?: Maybe<Scalars['Float']>;
};

export type ConditionnementInput = {
  conditionnement: Scalars['String'];
  labeling: Scalars['Boolean'];
  nbExConditionnement?: InputMaybe<Scalars['Float']>;
};

export type Contact = {
  __typename?: 'Contact';
  civility?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  functions?: Maybe<Scalars['String']>;
  gsmPhone?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inactif?: Maybe<Scalars['Boolean']>;
  landlinePhone?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  privatePhone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContactInput = {
  civility?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  functions?: InputMaybe<Scalars['String']>;
  gsmPhone?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inactif?: InputMaybe<Scalars['Boolean']>;
  landlinePhone?: InputMaybe<Scalars['String']>;
  linkedIn?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  privatePhone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ControlReceptionDetails = {
  __typename?: 'ControlReceptionDetails';
  conformity?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  receptionDone?: Maybe<Scalars['Boolean']>;
  shop: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type Department = {
  __typename?: 'Department';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type DepartmentInput = {
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type DiscountInput = {
  ca?: InputMaybe<Scalars['Float']>;
  rfa?: InputMaybe<Scalars['Float']>;
};

export type DiscountObjectType = {
  __typename?: 'DiscountObjectType';
  ca?: Maybe<Scalars['Float']>;
  rfa?: Maybe<Scalars['Float']>;
};

export type Distribution = {
  __typename?: 'Distribution';
  quantity?: Maybe<Scalars['Float']>;
  zip?: Maybe<Scalars['String']>;
};

export type DistributionInput = {
  quantity?: InputMaybe<Scalars['Float']>;
  zip?: InputMaybe<Scalars['String']>;
};

export enum ElpevSite {
  Empty = 'Empty',
  Lille = 'Lille',
  Paris = 'Paris',
  Wittelsheim = 'Wittelsheim',
}

export enum EntityType {
  Brand = 'Brand',
  Central = 'Central',
  Cooperative = 'Cooperative',
  Group = 'Group',
  Region = 'Region',
  Store = 'Store',
  SubGroup1 = 'SubGroup1',
  SubGroup2 = 'SubGroup2',
  SubGroup3 = 'SubGroup3',
  SubGroup4 = 'SubGroup4',
  Typology = 'Typology',
}

export type EntityTypeFilter = {
  equals?: InputMaybe<EntityType>;
  in?: InputMaybe<Array<EntityType>>;
  not?: InputMaybe<EntityType>;
  notIn?: InputMaybe<Array<EntityType>>;
};

export type EscompteInput = {
  client?: InputMaybe<Scalars['String']>;
  dayLimitTime?: InputMaybe<Scalars['Float']>;
  escompte?: InputMaybe<Scalars['Float']>;
};

export type EscompteObjectType = {
  __typename?: 'EscompteObjectType';
  client?: Maybe<Scalars['String']>;
  dayLimitTime?: Maybe<Scalars['Float']>;
  escompte?: Maybe<Scalars['Float']>;
};

export type ExportQuoteFile = {
  __typename?: 'ExportQuoteFile';
  filename: Scalars['String'];
  url: Scalars['String'];
};

export type FabricationTime = {
  __typename?: 'FabricationTime';
  expressMode?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  standardMode?: Maybe<Scalars['Float']>;
  urgentMode?: Maybe<Scalars['Float']>;
};

export type FabricationTimeBySupport = {
  __typename?: 'FabricationTimeBySupport';
  expressMode?: Maybe<Scalars['Float']>;
  standardMode?: Maybe<Scalars['Float']>;
};

export type FabricationTimeBySupportInput = {
  quantity: Scalars['Float'];
  supportId: Scalars['String'];
};

export type FabricationTimeInput = {
  expressMode?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  standardMode?: InputMaybe<Scalars['Float']>;
  urgentMode?: InputMaybe<Scalars['Float']>;
};

export type FaconnageDetails = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type FamilyAttributes = {
  __typename?: 'FamilyAttributes';
  characteristics: Array<CharacteristicsQuoteAttribute>;
  family: Scalars['String'];
};

export type FileReference = {
  __typename?: 'FileReference';
  askBy?: Maybe<Scalars['String']>;
  client: Client;
  clientReference?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  elpevResponsible: User;
  elpevResponsibleId: Scalars['String'];
  id: Scalars['String'];
  operationName: Scalars['String'];
  source: Source;
  status: FileReferenceStatus;
};

export type FileReferenceFilterInput = {
  clientId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  operationName?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Source>;
};

export type FileReferenceInsertInput = {
  askBy?: InputMaybe<Scalars['String']>;
  clientId: Scalars['String'];
  clientReference?: InputMaybe<Scalars['String']>;
  elpevResponsibleId: Scalars['String'];
  operationName: Scalars['String'];
  source?: InputMaybe<Source>;
};

export type FileReferenceSearchInput = {
  askBy?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  clientReference?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  operationName?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Source>;
  userName?: InputMaybe<Scalars['String']>;
};

export type FileReferenceSortInput = {
  askBy?: InputMaybe<SortOrder>;
  clientId?: InputMaybe<SortOrder>;
  clientName?: InputMaybe<SortOrder>;
  clientReference?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  operationName?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export enum FileReferenceStatus {
  Canceled = 'Canceled',
  Delivered = 'Delivered',
  InProduction = 'InProduction',
  InProgress = 'InProgress',
  InStudy = 'InStudy',
}

export type FileReferenceWithPaginationModel = {
  __typename?: 'FileReferenceWithPaginationModel';
  fileReferences: Array<FileReference>;
  pagination?: Maybe<Pagination>;
};

export type FiltredClientPriceInput = {
  clientId: Scalars['String'];
  productAttributes?: InputMaybe<AttributesFilterInput>;
  productId?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<ClientPriceStatus>;
};

export enum FollowOrderStatus {
  Billed = 'Billed',
  Delivered = 'Delivered',
  DeliveryInProgress = 'DeliveryInProgress',
  ProductionInProgress = 'ProductionInProgress',
  Refused = 'Refused',
  Validated = 'Validated',
  WaitingValidation = 'WaitingValidation',
}

export type HardlyAccessibleAreasTransporter = {
  __typename?: 'HardlyAccessibleAreasTransporter';
  postalCode?: Maybe<Scalars['String']>;
};

export type HardlyAccessibleAreasTransporterInput = {
  postalCode?: InputMaybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isExternal: Scalars['Boolean'];
  order: Order;
  orderId?: Maybe<Scalars['String']>;
  sendAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InvoiceStatus>;
  url: Scalars['String'];
  validateAt?: Maybe<Scalars['DateTime']>;
  validator?: Maybe<Scalars['String']>;
};

export type InvoiceFilterInput = {
  status?: InputMaybe<InvoiceStatus>;
};

export type InvoiceOrderItemInput = {
  id?: InputMaybe<Scalars['String']>;
  isWithRfaProduct?: InputMaybe<Scalars['Boolean']>;
  isWithRfaTransport?: InputMaybe<Scalars['Boolean']>;
  realProductMargin?: InputMaybe<Scalars['Float']>;
  realTransportMargin?: InputMaybe<Scalars['Float']>;
};

export enum InvoiceStatus {
  Blank = 'Blank',
  Counted = 'Counted',
  ToCount = 'ToCount',
  ToValidate = 'ToValidate',
}

export type InvoiceUpsertInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal: Scalars['Boolean'];
  order?: InputMaybe<CockpitOrderUpsertInput>;
  orderId?: InputMaybe<Scalars['String']>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<InvoiceStatus>;
  validateAt?: InputMaybe<Scalars['DateTime']>;
  validator?: InputMaybe<Scalars['String']>;
};

export type InvoiceValidationInput = {
  id?: InputMaybe<Scalars['String']>;
  orderItems?: InputMaybe<Array<InvoiceOrderItemInput>>;
  status?: InputMaybe<Scalars['String']>;
};

export type InvoiceWithPagination = {
  __typename?: 'InvoiceWithPagination';
  invoices: Array<Invoice>;
  pagination?: Maybe<Pagination>;
};

export type InvoicesToUpdateInput = {
  ids: Array<Scalars['String']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  projectName: ProjectName;
};

export enum MarginType {
  Euro = 'euro',
  Percent = 'percent',
}

export type Margins = {
  __typename?: 'Margins';
  autrePrixFixe: Scalars['Float'];
  prixCalage: Scalars['Float'];
  prixChangementPlaque: Scalars['Float'];
  prixCliche: Scalars['Float'];
  prixExemplaireSupp: Scalars['Float'];
  prixHorsCalage: Scalars['Float'];
  prixOutillage: Scalars['Float'];
  prixTraitementFichier: Scalars['Float'];
};

export type MarginsInput = {
  autrePrixFixe: Scalars['Float'];
  prixCalage: Scalars['Float'];
  prixChangementPlaque: Scalars['Float'];
  prixCliche: Scalars['Float'];
  prixExemplaireSupp: Scalars['Float'];
  prixHorsCalage: Scalars['Float'];
  prixOutillage: Scalars['Float'];
  prixTraitementFichier: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createClientLogoUploadUrl: Scalars['String'];
  createClientQuoteConfig: ClientQuoteConfigAttributesCreateModel;
  createOrderInvoiceUploadUrl: Scalars['String'];
  createRole: Role;
  createUser: User;
  deleteShippingAddressById: ClientShippingAddress;
  generateOrderPdf: OrderPdfInvoiceStatus;
  generateQuoteFile: ExportQuoteFile;
  getOrCreateProduct: Product;
  importClients: Array<Client>;
  importProductsSellingPrice: Array<Product>;
  insertClient: Client;
  insertClientShippingAddress: ClientShippingAddress;
  insertFileReference: FileReference;
  insertPrintValues: Array<PrintValue>;
  insertSupplier: Supplier;
  insertSuppliersOrders: Array<SupplierOrder>;
  insertSupport: Support;
  insertTransporter: Transporter;
  login: Auth;
  sendOrderEmail: OrderEmailStatus;
  updateClient: Client;
  updateClientByCompanyBase: Client;
  updateCockpitOrder: Order;
  updateOneOrManyInvoices: Array<Scalars['String']>;
  updateRole: Role;
  updateSupplier: Supplier;
  updateSupplierByCompanyBase: Supplier;
  updateSupportAttributes: Support;
  updateTransporters: Array<Transporter>;
  updateUser: User;
  upsertBigShopConfig: BigShopConfig;
  upsertClariprintClientPrice: ClientPrice;
  upsertClientPrice: ClientPrice;
  upsertClientQuote: ClientQuote;
  upsertCockpitOrder: Order;
  upsertInvoice: Invoice;
  upsertOrder: Order;
  validateInvoice: Invoice;
};

export type MutationCreateClientLogoUploadUrlArgs = {
  id: Scalars['String'];
  mimetype: Scalars['String'];
};

export type MutationCreateClientQuoteConfigArgs = {
  clientQuoteConfig: ClientQuoteConfigAttributesCreateInput;
};

export type MutationCreateOrderInvoiceUploadUrlArgs = {
  id: Scalars['String'];
  mimetype: Scalars['String'];
};

export type MutationCreateRoleArgs = {
  data: RoleInput;
};

export type MutationCreateUserArgs = {
  data: UserCreateInput;
};

export type MutationDeleteShippingAddressByIdArgs = {
  addressId: Scalars['String'];
  clientShippingAddressId: Scalars['String'];
};

export type MutationGenerateOrderPdfArgs = {
  invoiceId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
};

export type MutationGenerateQuoteFileArgs = {
  filter: ClientQuoteGenerateFileInput;
};

export type MutationGetOrCreateProductArgs = {
  product: ProductCreateInput;
};

export type MutationImportClientsArgs = {
  file: Scalars['Upload'];
  rootClientName: Scalars['String'];
};

export type MutationImportProductsSellingPriceArgs = {
  file: Scalars['Upload'];
};

export type MutationInsertClientArgs = {
  client: ClientInsertInput;
};

export type MutationInsertClientShippingAddressArgs = {
  clientShippingAddress: ClientShippingAddressInsertInput;
};

export type MutationInsertFileReferenceArgs = {
  fileReference: FileReferenceInsertInput;
};

export type MutationInsertPrintValuesArgs = {
  prints: Array<PrintValueInput>;
};

export type MutationInsertSupplierArgs = {
  name: Scalars['String'];
};

export type MutationInsertSuppliersOrdersArgs = {
  supplierOrderInsertInput: Array<SupplierOrderInsertInput>;
};

export type MutationInsertSupportArgs = {
  name: Scalars['String'];
};

export type MutationInsertTransporterArgs = {
  name: Scalars['String'];
};

export type MutationLoginArgs = {
  data: LoginInput;
};

export type MutationSendOrderEmailArgs = {
  order: CockpitOrderUpsertInput;
};

export type MutationUpdateClientArgs = {
  client: ClientUpdateInput;
};

export type MutationUpdateClientByCompanyBaseArgs = {
  clientId: Scalars['String'];
  companyBase: Scalars['String'];
};

export type MutationUpdateCockpitOrderArgs = {
  payload: CockpitOrderUpdateInput;
};

export type MutationUpdateOneOrManyInvoicesArgs = {
  invoiceIds: InvoicesToUpdateInput;
};

export type MutationUpdateRoleArgs = {
  data: RoleInput;
  roleId: Scalars['String'];
};

export type MutationUpdateSupplierArgs = {
  data: SupplierUpdateInput;
  supplierId: Scalars['String'];
};

export type MutationUpdateSupplierByCompanyBaseArgs = {
  companyBase: Scalars['String'];
  supplierId: Scalars['String'];
};

export type MutationUpdateSupportAttributesArgs = {
  data: SupportUpdateInput;
};

export type MutationUpdateTransportersArgs = {
  Transporters: Array<TransporterUpdateInput>;
};

export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  userId: Scalars['String'];
};

export type MutationUpsertBigShopConfigArgs = {
  data: BigShopConfigUpsertInput;
};

export type MutationUpsertClariprintClientPriceArgs = {
  filter: ProductClientPriceCreateInput;
};

export type MutationUpsertClientPriceArgs = {
  clientPrice: ClientPriceInput;
};

export type MutationUpsertClientQuoteArgs = {
  data: ClientQuoteUpsertInput;
};

export type MutationUpsertCockpitOrderArgs = {
  order: CockpitOrderUpsertInput;
};

export type MutationUpsertInvoiceArgs = {
  invoice: InvoiceUpsertInput;
};

export type MutationUpsertOrderArgs = {
  order: UpsertOrderInput;
};

export type MutationValidateInvoiceArgs = {
  invoice: InvoiceValidationInput;
};

export type Order = {
  __typename?: 'Order';
  billingAddress: OrderAddress;
  billingEntity?: Maybe<Scalars['String']>;
  buyingPriceHt: Scalars['Float'];
  buyingPriceHtWithRfa?: Maybe<Scalars['Float']>;
  childrenOrder?: Maybe<Array<Order>>;
  client?: Maybe<OrderClient>;
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  clientQuote?: Maybe<ClientQuote>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  elepvContactName?: Maybe<Scalars['String']>;
  elpevContactEmail?: Maybe<Scalars['String']>;
  elpevContactPhone?: Maybe<Scalars['String']>;
  fileReference?: Maybe<FileReference>;
  followStatus: FollowOrderStatus;
  id: Scalars['String'];
  invoices?: Maybe<Array<Invoice>>;
  isDistribution: Scalars['Boolean'];
  marginRate?: Maybe<Scalars['Float']>;
  marginWithClientRfa?: Maybe<Scalars['Float']>;
  onepacUserId?: Maybe<Scalars['String']>;
  onepacUserName?: Maybe<Scalars['String']>;
  orderDistributions?: Maybe<Array<OrderDistributions>>;
  orderItem: Array<OrderItem>;
  parentOrderId?: Maybe<Scalars['String']>;
  paymentType?: Maybe<OrderPaymentType>;
  prepressPriceHt?: Maybe<Scalars['Float']>;
  prepressSellingPriceHt?: Maybe<Scalars['Float']>;
  productSellingPriceHt?: Maybe<Scalars['Float']>;
  realMargin?: Maybe<Scalars['Float']>;
  sellingPriceHt: Scalars['Float'];
  sellingPriceTtc?: Maybe<Scalars['Float']>;
  source?: Maybe<OrderSource>;
  status?: Maybe<OrderStatus>;
  statusComment?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  transportBuyingPriceHt?: Maybe<Scalars['Float']>;
  transportSellingPriceHt?: Maybe<Scalars['Float']>;
  tva?: Maybe<Scalars['Float']>;
  tvaRate?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Float']>;
  versionsNumber?: Maybe<Scalars['Float']>;
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deliveryDepartment?: Maybe<Scalars['String']>;
  firstAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postBox?: Maybe<Scalars['String']>;
  secondAddress?: Maybe<Scalars['String']>;
  thirdAddress?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OrderClient = {
  __typename?: 'OrderClient';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type OrderControlReception = {
  __typename?: 'OrderControlReception';
  articleId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  receptionDetails?: Maybe<Array<ControlReceptionDetails>>;
};

export type OrderDeliveries = {
  __typename?: 'OrderDeliveries';
  deliveryPoints?: Maybe<Array<OrderDeliveryPoints>>;
  error?: Maybe<Scalars['Float']>;
  nbPackages?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['Float']>;
};

export type OrderDeliveryControls = {
  __typename?: 'OrderDeliveryControls';
  answerDate?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  eventClosed?: Maybe<Scalars['Boolean']>;
  eventComment?: Maybe<Scalars['String']>;
  eventHistoryHtml?: Maybe<Scalars['String']>;
  receptions?: Maybe<Array<OrderControlReception>>;
};

export type OrderDeliveryPackages = {
  __typename?: 'OrderDeliveryPackages';
  carrier?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryProofLink?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  numberOfPackages?: Maybe<Scalars['Float']>;
  receiver?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export type OrderDeliveryPoints = {
  __typename?: 'OrderDeliveryPoints';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  controls?: Maybe<Array<OrderDeliveryControls>>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<OrderDeliveryPackages>>;
  status: Scalars['Float'];
  town?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OrderDistributionsUpsertInput = {
  clientId: Scalars['String'];
  clientShippingAddressId: Scalars['String'];
  clientShippingAddressName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
};

export type OrderEmailStatus = {
  __typename?: 'OrderEmailStatus';
  description: Scalars['String'];
  success: Scalars['Boolean'];
};

export type OrderFilterInput = {
  childrenOrderClientIds?: InputMaybe<Array<Scalars['String']>>;
  clientId?: InputMaybe<Scalars['String']>;
  onePacDocumentIds?: InputMaybe<Array<Scalars['String']>>;
  source?: InputMaybe<OrderSource>;
  status?: InputMaybe<OrderStatus>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  assetUrl?: Maybe<Array<Scalars['String']>>;
  bestDeliveryParcelPurchasePrice: Scalars['Float'];
  bestSupplier?: Maybe<Supplier>;
  bestSupplierId?: Maybe<Scalars['String']>;
  bestTransporterId?: Maybe<Scalars['String']>;
  buyingPriceHt?: Maybe<Scalars['Float']>;
  clientNetworkLink?: Maybe<Scalars['String']>;
  clientReference?: Maybe<Scalars['String']>;
  collectionComment?: Maybe<Scalars['String']>;
  collectionDate?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  commentTransporter?: Maybe<Scalars['String']>;
  corsicaDeliveryExtra: Scalars['Float'];
  deliveryComment?: Maybe<Scalars['String']>;
  deliveryDepartment?: Maybe<Scalars['String']>;
  deliveryParcel: BoxShippingMode;
  expectedDeliveryDate: Scalars['DateTime'];
  id: Scalars['String'];
  imgUrl?: Maybe<Scalars['String']>;
  isDelivered?: Maybe<Scalars['Boolean']>;
  isFinalDistribution: Scalars['Boolean'];
  isMechanizable: Scalars['Boolean'];
  isWithRfaProduct: Scalars['Boolean'];
  isWithRfaTransport: Scalars['Boolean'];
  legacyRef?: Maybe<Scalars['String']>;
  manufacturingProgress: Scalars['Boolean'];
  numberFileProcessing?: Maybe<Scalars['Float']>;
  numberPlateChanges?: Maybe<Scalars['Float']>;
  numberShots?: Maybe<Scalars['Float']>;
  numberVersion?: Maybe<Scalars['Float']>;
  onePacDistributionId?: Maybe<Scalars['String']>;
  onePacDistributionName?: Maybe<Scalars['String']>;
  onePacDistributionUrl?: Maybe<Scalars['String']>;
  onePacDocumentId: Scalars['String'];
  onePacDocumentName?: Maybe<Scalars['String']>;
  onePacReplicationName?: Maybe<Scalars['String']>;
  onePacReplicationUrl?: Maybe<Scalars['String']>;
  onePacThematicName?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  postDescription?: Maybe<Scalars['String']>;
  prepressPriceHt?: Maybe<Scalars['Float']>;
  prepressSellingPriceHt?: Maybe<Scalars['Float']>;
  product: Product;
  productId: Scalars['String'];
  productMargin: Scalars['Float'];
  productName: Scalars['String'];
  productPurchasePriceExcludingVAT: Scalars['Float'];
  productPurchasePriceExcludingVATWithRFA: Scalars['Float'];
  productSellingPriceHt?: Maybe<Scalars['Float']>;
  productTotalWeight: Scalars['Float'];
  provisionDate?: Maybe<Scalars['DateTime']>;
  publicationDate?: Maybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  realProductMargin: Scalars['Float'];
  realTransportMargin: Scalars['Float'];
  resources: Array<Resource>;
  rfaRate: Scalars['Float'];
  sellingPriceHt?: Maybe<Scalars['Float']>;
  sellingPriceTtc?: Maybe<Scalars['Float']>;
  shippingAddress?: Maybe<OrderAddress>;
  shippingProgress: Scalars['Boolean'];
  status?: Maybe<OrderProductStatus>;
  supplierComment?: Maybe<Scalars['String']>;
  supplierRfaRate: Scalars['Float'];
  supplierRspRate: Scalars['Float'];
  suppliersPrices?: Maybe<Array<OrderItemSuppliersPricesModel>>;
  thumbnailAssetId?: Maybe<Scalars['String']>;
  thumbnailAssetUrl?: Maybe<Scalars['String']>;
  tools?: Maybe<Scalars['Boolean']>;
  totalPurchasePriceExcludingTax: Scalars['Float'];
  trackingLink?: Maybe<Scalars['String']>;
  transportBuyingPriceHt?: Maybe<Scalars['Float']>;
  transportMargin: Scalars['Float'];
  transportMoment: TransportMoment;
  transportPurchasePriceExcludingVAT: Scalars['Float'];
  transportPurchasePriceHt: Scalars['Float'];
  transportPurchasePriceHtWithRfa: Scalars['Float'];
  transportRfa: Scalars['Float'];
  transportSellingPriceByDefaultHt: Scalars['Float'];
  transportSellingPriceHt?: Maybe<Scalars['Float']>;
  transportSellingPriceHtAdditionalMargin: MarginType;
  transportSellingPriceHtWithClientRfa: Scalars['Float'];
  transportSellingPriceHtWithClientRfaRectified: Scalars['Float'];
  transportSellingPriceMargin: Scalars['Float'];
  transportType: TransportType;
  transporterPurchaseExcludingVATWithRfa: Scalars['Float'];
  transporterPurchasePriceExcludingVAT: Scalars['Float'];
  transporterRfaRate: Scalars['Float'];
  transportersPrices?: Maybe<Array<OrderItemTransportersPricesModel>>;
  tva?: Maybe<Scalars['Float']>;
  tvaRate?: Maybe<Scalars['Float']>;
  versionsNumber?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  /**
   * OrderItem weight
   * @deprecated Use weight instead
   */
  weigth?: Maybe<Scalars['Float']>;
};

export type OrderItemAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  deliveryDepartment?: InputMaybe<Scalars['String']>;
  firstAddress?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  postBox?: InputMaybe<Scalars['String']>;
  secondAddress?: InputMaybe<Scalars['String']>;
  thirdAddress?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type OrderItemCockpitUpsertInput = {
  assetId?: InputMaybe<Scalars['String']>;
  buyingPriceHt?: InputMaybe<Scalars['Float']>;
  clientNetworkLink?: InputMaybe<Scalars['String']>;
  clientNetworkPageId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  expectedDeliveryDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  isWithRfaProduct?: InputMaybe<Scalars['Boolean']>;
  isWithRfaTransport?: InputMaybe<Scalars['Boolean']>;
  legacyRef?: InputMaybe<Scalars['String']>;
  onePacDistributionId?: InputMaybe<Scalars['String']>;
  onePacDistributionName?: InputMaybe<Scalars['String']>;
  onePacDocumentId: Scalars['String'];
  onePacDocumentName?: InputMaybe<Scalars['String']>;
  onePacReplicationId?: InputMaybe<Scalars['String']>;
  onePacReplicationName?: InputMaybe<Scalars['String']>;
  onePacThematicName?: InputMaybe<Scalars['String']>;
  postDescription?: InputMaybe<Scalars['String']>;
  prepressPriceHt?: InputMaybe<Scalars['Float']>;
  prepressSellingPriceHt?: InputMaybe<Scalars['Float']>;
  productId: Scalars['String'];
  productMargin?: InputMaybe<Scalars['Float']>;
  productName: Scalars['String'];
  productSellingPriceHt?: InputMaybe<Scalars['Float']>;
  publicationDate?: InputMaybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  realProductMargin?: InputMaybe<Scalars['Float']>;
  realTransportMargin?: InputMaybe<Scalars['Float']>;
  resources?: InputMaybe<Array<ResourceInput>>;
  rfaRate?: InputMaybe<Scalars['Float']>;
  sellingPriceHt?: InputMaybe<Scalars['Float']>;
  sellingPriceTtc?: InputMaybe<Scalars['Float']>;
  shippingAddress?: InputMaybe<OrderItemAddressInput>;
  socialNetwork?: InputMaybe<SocialNetworksInput>;
  status?: InputMaybe<OrderProductStatus>;
  thumbnailAssetId?: InputMaybe<Scalars['String']>;
  trackingLink?: InputMaybe<Scalars['String']>;
  transportBuyingPriceHt?: InputMaybe<Scalars['Float']>;
  transportMargin?: InputMaybe<Scalars['Float']>;
  transportSellingPriceHt?: InputMaybe<Scalars['Float']>;
  tva?: InputMaybe<Scalars['Float']>;
  tvaRate?: InputMaybe<Scalars['Float']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type OrderItemSupplierPricesInput = {
  buyingPriceHtWithoutRspAndRfa: Scalars['Float'];
  supplierId: Scalars['String'];
  supplierName: Scalars['String'];
  supplierPrice: Scalars['Float'];
  supplierRfaRate: Scalars['Float'];
  supplierRspRate: Scalars['Float'];
};

export type OrderItemSuppliersPricesModel = {
  __typename?: 'OrderItemSuppliersPricesModel';
  buyingPriceHtWithoutRspAndRfa: Scalars['Float'];
  supplierId: Scalars['String'];
  supplierName: Scalars['String'];
  supplierPrice: Scalars['Float'];
  supplierRfaRate: Scalars['Float'];
  supplierRspRate: Scalars['Float'];
};

export type OrderItemTransportersPricesInput = {
  transporterId: Scalars['String'];
  transporterName: Scalars['String'];
  transporterPurchaseExcludingVATWithRfa: Scalars['Float'];
  transporterPurchasePriceExcludingVAT: Scalars['Float'];
  transporterRfaRate: Scalars['Float'];
};

export type OrderItemTransportersPricesModel = {
  __typename?: 'OrderItemTransportersPricesModel';
  orderItemId: Scalars['String'];
  transporterId: Scalars['String'];
  transporterName: Scalars['String'];
  transporterPurchaseExcludingVATWithRfa: Scalars['Float'];
  transporterPurchasePriceExcludingVAT: Scalars['Float'];
  transporterRfaRate: Scalars['Float'];
};

export type OrderItemUpsertInput = {
  bestDeliveryParcelPurchasePrice?: InputMaybe<Scalars['Float']>;
  bestSupplierId?: InputMaybe<Scalars['String']>;
  bestTransporterId?: InputMaybe<Scalars['String']>;
  buyingPriceHt?: InputMaybe<Scalars['Float']>;
  collectionComment?: InputMaybe<Scalars['String']>;
  collectionDate?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  commentTransporter?: InputMaybe<Scalars['String']>;
  corsicaDeliveryExtra: Scalars['Float'];
  deliveryComment?: InputMaybe<Scalars['String']>;
  deliveryDepartment?: InputMaybe<Scalars['String']>;
  deliveryParcel: BoxShippingMode;
  expectedDeliveryDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  isFinalDistribution: Scalars['Boolean'];
  isMechanizable: Scalars['Boolean'];
  legacyRef?: InputMaybe<Scalars['String']>;
  numberFileProcessing?: InputMaybe<Scalars['Float']>;
  numberPlateChanges?: InputMaybe<Scalars['Float']>;
  numberShots?: InputMaybe<Scalars['Float']>;
  numberVersion?: InputMaybe<Scalars['Float']>;
  onePacDocumentId?: InputMaybe<Scalars['String']>;
  prepressPriceHt?: InputMaybe<Scalars['Float']>;
  prepressSellingPriceHt?: InputMaybe<Scalars['Float']>;
  productId: Scalars['String'];
  productName: Scalars['String'];
  productPurchasePriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  productPurchasePriceExcludingVATWithRFA?: InputMaybe<Scalars['Float']>;
  productSellingPriceHt?: InputMaybe<Scalars['Float']>;
  productTotalWeight?: InputMaybe<Scalars['Float']>;
  provisionDate?: InputMaybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  sellingPriceHt?: InputMaybe<Scalars['Float']>;
  sellingPriceTtc?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<OrderProductStatus>;
  supplierComment?: InputMaybe<Scalars['String']>;
  supplierRfaRate?: InputMaybe<Scalars['Float']>;
  supplierRspRate?: InputMaybe<Scalars['Float']>;
  suppliersPrices?: InputMaybe<Array<OrderItemSupplierPricesInput>>;
  tools?: InputMaybe<Scalars['Boolean']>;
  totalPurchasePriceExcludingTax?: InputMaybe<Scalars['Float']>;
  trackingLink?: InputMaybe<Scalars['String']>;
  transportBuyingPriceHt?: InputMaybe<Scalars['Float']>;
  transportMoment: TransportMoment;
  transportPurchasePriceExcludingVAT: Scalars['Float'];
  transportPurchasePriceHt: Scalars['Float'];
  transportPurchasePriceHtWithRfa: Scalars['Float'];
  transportRfa: Scalars['Float'];
  transportSellingPriceByDefaultHt: Scalars['Float'];
  transportSellingPriceHt?: InputMaybe<Scalars['Float']>;
  transportSellingPriceHtAdditionalMargin: MarginType;
  transportSellingPriceHtWithClientRfa: Scalars['Float'];
  transportSellingPriceHtWithClientRfaRectified: Scalars['Float'];
  transportSellingPriceMargin: Scalars['Float'];
  transportType: TransportType;
  transporterPurchaseExcludingVATWithRfa?: InputMaybe<Scalars['Float']>;
  transporterPurchasePriceExcludingVAT?: InputMaybe<Scalars['Float']>;
  transporterRfaRate?: InputMaybe<Scalars['Float']>;
  transportersPrices?: InputMaybe<Array<OrderItemTransportersPricesInput>>;
  tva?: InputMaybe<Scalars['Float']>;
  tvaRate?: InputMaybe<Scalars['Float']>;
  versionsNumber?: InputMaybe<Scalars['Float']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export enum OrderPaymentType {
  ByCb = 'ByCB',
  ByInvoice = 'ByInvoice',
}

export type OrderPdfInvoiceStatus = {
  __typename?: 'OrderPdfInvoiceStatus';
  description: Scalars['String'];
  success: Scalars['Boolean'];
};

export enum OrderProductStatus {
  Delivered = 'Delivered',
  ManufacturingInProgress = 'ManufacturingInProgress',
  ShippingInProgress = 'ShippingInProgress',
  Waiting = 'Waiting',
}

export enum OrderSource {
  OnePacBigShop = 'OnePacBigShop',
  OneSupply = 'OneSupply',
}

export enum OrderStatus {
  Canceled = 'Canceled',
  Delivered = 'Delivered',
  InProgress = 'InProgress',
  WorkInProgress = 'WorkInProgress',
}

export type Pagination = {
  __typename?: 'Pagination';
  totalItemsCount: Scalars['Float'];
};

export type PaginationInput = {
  skip: Scalars['Float'];
  take: Scalars['Float'];
};

export enum PalletShippingMode {
  Other = 'Other',
  Strandard = 'Strandard',
  Tailgate = 'Tailgate',
}

export type Password = {
  __typename?: 'Password';
  password: Scalars['String'];
};

export type Permission = {
  __typename?: 'Permission';
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export enum PricesSource {
  ClariPrint = 'ClariPrint',
  ClariPrintOs = 'ClariPrintOs',
  Digital = 'Digital',
  ElpevBase = 'ElpevBase',
  OneSupply = 'OneSupply',
}

export type PrintValue = {
  __typename?: 'PrintValue';
  attribute: Attribute;
  attributeId: Scalars['String'];
  printName?: Maybe<Scalars['String']>;
  printValue: Scalars['String'];
};

export type PrintValueInput = {
  attributeId: Scalars['String'];
  printName?: InputMaybe<Scalars['String']>;
  printValue: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  certification?: Maybe<Scalars['String']>;
  chant?: Maybe<Scalars['String']>;
  ciblage?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  clientPrices?: Maybe<Array<ClientPrice>>;
  clientQuoteItem: ClientQuoteItem;
  codeAt: Scalars['String'];
  conditionnement?: Maybe<AttributConditionnement>;
  couleur?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  decoupe?: Maybe<Scalars['String']>;
  descriptionIndication?: Maybe<Scalars['String']>;
  diametreCm?: Maybe<Scalars['String']>;
  enveloppeFenetre?: Maybe<Scalars['String']>;
  epaisseurMicrons?: Maybe<Scalars['String']>;
  faconnage?: Maybe<Scalars['String']>;
  faconnageDeLivraison?: Maybe<Scalars['String']>;
  finitionRecto?: Maybe<Scalars['String']>;
  finitionVerso?: Maybe<Scalars['String']>;
  fondsPerdus?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  fourniture?: Maybe<Scalars['String']>;
  grammageCouvertureGr?: Maybe<Scalars['String']>;
  grammageGr?: Maybe<Scalars['String']>;
  grammageInterieurGr?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  impressionLogo?: Maybe<Scalars['String']>;
  impressionRectoVerso?: Maybe<Scalars['String']>;
  largeurCouvertureFormatFiniCm?: Maybe<Scalars['String']>;
  largeurCouvertureFormatOuvertCm?: Maybe<Scalars['String']>;
  largeurCouvertureFormatPageCm?: Maybe<Scalars['String']>;
  largeurFormatFiniCm?: Maybe<Scalars['String']>;
  largeurFormatOuvertCm?: Maybe<Scalars['String']>;
  largeurFormatPageCm?: Maybe<Scalars['String']>;
  largeurInterieurEncartFormatFiniCm?: Maybe<Scalars['String']>;
  largeurInterieurEncartFormatOuvertCm?: Maybe<Scalars['String']>;
  largeurInterieurEncartFormatPageCm?: Maybe<Scalars['String']>;
  largeurInterieurFormatFiniCm?: Maybe<Scalars['String']>;
  largeurInterieurFormatOuvertCm?: Maybe<Scalars['String']>;
  largeurInterieurFormatPageCm?: Maybe<Scalars['String']>;
  largeurPixel?: Maybe<Scalars['String']>;
  largeurTailleFenetreCm?: Maybe<Scalars['String']>;
  longueurCouvertureFormatFiniCm?: Maybe<Scalars['String']>;
  longueurCouvertureFormatOuvertCm?: Maybe<Scalars['String']>;
  longueurCouvertureFormatPageCm?: Maybe<Scalars['String']>;
  longueurFormatFiniCm?: Maybe<Scalars['String']>;
  longueurFormatOuvertCm?: Maybe<Scalars['String']>;
  longueurFormatPageCm?: Maybe<Scalars['String']>;
  longueurInterieurEncartFormatFiniCm?: Maybe<Scalars['String']>;
  longueurInterieurEncartFormatOuvertCm?: Maybe<Scalars['String']>;
  longueurInterieurEncartFormatPageCm?: Maybe<Scalars['String']>;
  longueurInterieurFormatFiniCm?: Maybe<Scalars['String']>;
  longueurInterieurFormatOuvertCm?: Maybe<Scalars['String']>;
  longueurInterieurFormatPageCm?: Maybe<Scalars['String']>;
  longueurPixel?: Maybe<Scalars['String']>;
  longueurTailleFenetreCm?: Maybe<Scalars['String']>;
  matiere?: Maybe<Scalars['String']>;
  matiereCouverture?: Maybe<Scalars['String']>;
  matiereInterieur?: Maybe<Scalars['String']>;
  modeleAchat?: Maybe<Scalars['String']>;
  nbCouleurCouvertureRecto?: Maybe<Scalars['String']>;
  nbCouleurCouvertureVerso?: Maybe<Scalars['String']>;
  nbCouleurInterieurEncartRecto?: Maybe<Scalars['String']>;
  nbCouleurInterieurEncartVerso?: Maybe<Scalars['String']>;
  nbCouleurInterieurRecto?: Maybe<Scalars['String']>;
  nbCouleurInterieurVerso?: Maybe<Scalars['String']>;
  nbCouleurRecto?: Maybe<Scalars['String']>;
  nbCouleurVerso?: Maybe<Scalars['String']>;
  objectifMarketing?: Maybe<Scalars['String']>;
  optionsFaconnage?: Maybe<Scalars['String']>;
  orientation?: Maybe<Scalars['String']>;
  ouvertureEnveloppe?: Maybe<Scalars['String']>;
  paginationCouverture?: Maybe<Scalars['String']>;
  paginationInterieur?: Maybe<Scalars['String']>;
  paginationInterieurEncart?: Maybe<Scalars['String']>;
  paginationTotale?: Maybe<Scalars['String']>;
  paysDeDiffusion?: Maybe<Scalars['String']>;
  precisionsFaconnage?: Maybe<Scalars['String']>;
  precisionsFourniture?: Maybe<Scalars['String']>;
  profondeurFormatFiniCm?: Maybe<Scalars['String']>;
  repiquage?: Maybe<Scalars['String']>;
  repiquageCoeur?: Maybe<Scalars['String']>;
  repiquageDOS?: Maybe<Scalars['String']>;
  support: Support;
  supportId: Scalars['String'];
  surfacage?: Maybe<Scalars['String']>;
  surfacageCouvertureRecto?: Maybe<Scalars['String']>;
  surfacageCouvertureVerso?: Maybe<Scalars['String']>;
  surfacageInterieurRecto?: Maybe<Scalars['String']>;
  surfacageInterieurVerso?: Maybe<Scalars['String']>;
  taille?: Maybe<Scalars['String']>;
  tauxSurfacage?: Maybe<Scalars['String']>;
  typeEnvoi?: Maybe<Scalars['String']>;
  typeImpression?: Maybe<Scalars['String']>;
  typeMatiere?: Maybe<Scalars['String']>;
  typeMatiereCouverture?: Maybe<Scalars['String']>;
  typeMatiereInterieur?: Maybe<Scalars['String']>;
  typePapier?: Maybe<Scalars['String']>;
  typeSupport?: Maybe<Scalars['String']>;
  unitWeightGr?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zoneTerritorial?: Maybe<Scalars['String']>;
};

export type ProductAttributesValues = {
  certification?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['String']>;
  conditionnement?: InputMaybe<AttributConditionnementInput>;
  decoupe?: InputMaybe<Scalars['String']>;
  faconnage?: InputMaybe<FaconnageDetails>;
  faconnageDeLivraison?: InputMaybe<Scalars['String']>;
  finitionRecto?: InputMaybe<Scalars['String']>;
  finitionVerso?: InputMaybe<Scalars['String']>;
  fondsPerdus?: InputMaybe<Scalars['String']>;
  grammageGr?: InputMaybe<Scalars['String']>;
  impressionRectoVerso?: InputMaybe<Scalars['String']>;
  largeurFormatFiniCm?: InputMaybe<Scalars['String']>;
  largeurFormatOuvertCm?: InputMaybe<Scalars['String']>;
  longueurFormatFiniCm?: InputMaybe<Scalars['String']>;
  longueurFormatOuvertCm?: InputMaybe<Scalars['String']>;
  nbCouleurRecto?: InputMaybe<Scalars['String']>;
  nbCouleurVerso?: InputMaybe<Scalars['String']>;
  optionsFaconnage?: InputMaybe<FaconnageDetails>;
  precisionsFaconnage?: InputMaybe<FaconnageDetails>;
  surfacage?: InputMaybe<Scalars['String']>;
  typeMatiere?: InputMaybe<Scalars['String']>;
  typePapier?: InputMaybe<Scalars['String']>;
};

export type ProductClientPrice = {
  __typename?: 'ProductClientPrice';
  numberVersion: Scalars['Float'];
  productId: Scalars['String'];
  productSalesPriceExcludingVAT: Scalars['Float'];
  quantity: Scalars['Float'];
};

export type ProductClientPriceCreateInput = {
  clientPrice: ClientPriceInput;
  product: ProductCreateInput;
};

export type ProductCreateInput = {
  certification?: InputMaybe<Scalars['String']>;
  chant?: InputMaybe<Scalars['String']>;
  ciblage?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['String']>;
  conditionnement?: InputMaybe<AttributConditionnementInput>;
  couleur?: InputMaybe<Scalars['String']>;
  decoupe?: InputMaybe<Scalars['String']>;
  descriptionIndication?: InputMaybe<Scalars['String']>;
  diametreCm?: InputMaybe<Scalars['String']>;
  enveloppeFenetre?: InputMaybe<Scalars['String']>;
  epaisseurMicrons?: InputMaybe<Scalars['String']>;
  faconnage?: InputMaybe<Scalars['String']>;
  faconnageDeLivraison?: InputMaybe<Scalars['String']>;
  finitionRecto?: InputMaybe<Scalars['String']>;
  finitionVerso?: InputMaybe<Scalars['String']>;
  fondsPerdus?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  fourniture?: InputMaybe<Scalars['String']>;
  grammageCouvertureGr?: InputMaybe<Scalars['String']>;
  grammageGr?: InputMaybe<Scalars['String']>;
  grammageInterieurGr?: InputMaybe<Scalars['String']>;
  impressionLogo?: InputMaybe<Scalars['String']>;
  impressionRectoVerso?: InputMaybe<Scalars['String']>;
  largeurCouvertureFormatFiniCm?: InputMaybe<Scalars['String']>;
  largeurCouvertureFormatOuvertCm?: InputMaybe<Scalars['String']>;
  largeurCouvertureFormatPageCm?: InputMaybe<Scalars['String']>;
  largeurFormatFiniCm?: InputMaybe<Scalars['String']>;
  largeurFormatOuvertCm?: InputMaybe<Scalars['String']>;
  largeurFormatPageCm?: InputMaybe<Scalars['String']>;
  largeurInterieurEncartFormatFiniCm?: InputMaybe<Scalars['String']>;
  largeurInterieurEncartFormatOuvertCm?: InputMaybe<Scalars['String']>;
  largeurInterieurEncartFormatPageCm?: InputMaybe<Scalars['String']>;
  largeurInterieurFormatFiniCm?: InputMaybe<Scalars['String']>;
  largeurInterieurFormatOuvertCm?: InputMaybe<Scalars['String']>;
  largeurInterieurFormatPageCm?: InputMaybe<Scalars['String']>;
  largeurPixel?: InputMaybe<Scalars['String']>;
  largeurTailleFenetreCm?: InputMaybe<Scalars['String']>;
  longueurCouvertureFormatFiniCm?: InputMaybe<Scalars['String']>;
  longueurCouvertureFormatOuvertCm?: InputMaybe<Scalars['String']>;
  longueurCouvertureFormatPageCm?: InputMaybe<Scalars['String']>;
  longueurFormatFiniCm?: InputMaybe<Scalars['String']>;
  longueurFormatOuvertCm?: InputMaybe<Scalars['String']>;
  longueurFormatPageCm?: InputMaybe<Scalars['String']>;
  longueurInterieurEncartFormatFiniCm?: InputMaybe<Scalars['String']>;
  longueurInterieurEncartFormatOuvertCm?: InputMaybe<Scalars['String']>;
  longueurInterieurEncartFormatPageCm?: InputMaybe<Scalars['String']>;
  longueurInterieurFormatFiniCm?: InputMaybe<Scalars['String']>;
  longueurInterieurFormatOuvertCm?: InputMaybe<Scalars['String']>;
  longueurInterieurFormatPageCm?: InputMaybe<Scalars['String']>;
  longueurPixel?: InputMaybe<Scalars['String']>;
  longueurTailleFenetreCm?: InputMaybe<Scalars['String']>;
  matiere?: InputMaybe<Scalars['String']>;
  matiereCouverture?: InputMaybe<Scalars['String']>;
  matiereInterieur?: InputMaybe<Scalars['String']>;
  modeleAchat?: InputMaybe<Scalars['String']>;
  nbCouleurCouvertureRecto?: InputMaybe<Scalars['String']>;
  nbCouleurCouvertureVerso?: InputMaybe<Scalars['String']>;
  nbCouleurInterieurEncartRecto?: InputMaybe<Scalars['String']>;
  nbCouleurInterieurEncartVerso?: InputMaybe<Scalars['String']>;
  nbCouleurInterieurRecto?: InputMaybe<Scalars['String']>;
  nbCouleurInterieurVerso?: InputMaybe<Scalars['String']>;
  nbCouleurRecto?: InputMaybe<Scalars['String']>;
  nbCouleurVerso?: InputMaybe<Scalars['String']>;
  objectifMarketing?: InputMaybe<Scalars['String']>;
  optionsFaconnage?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<Scalars['String']>;
  ouvertureEnveloppe?: InputMaybe<Scalars['String']>;
  paginationCouverture?: InputMaybe<Scalars['String']>;
  paginationInterieur?: InputMaybe<Scalars['String']>;
  paginationInterieurEncart?: InputMaybe<Scalars['String']>;
  paginationTotale?: InputMaybe<Scalars['String']>;
  paysDeDiffusion?: InputMaybe<Scalars['String']>;
  precisionsFaconnage?: InputMaybe<Scalars['String']>;
  precisionsFourniture?: InputMaybe<Scalars['String']>;
  profondeurFormatFiniCm?: InputMaybe<Scalars['String']>;
  repiquage?: InputMaybe<Scalars['String']>;
  repiquageCoeur?: InputMaybe<Scalars['String']>;
  repiquageDOS?: InputMaybe<Scalars['String']>;
  supportId: Scalars['String'];
  surfacage?: InputMaybe<Scalars['String']>;
  surfacageCouvertureRecto?: InputMaybe<Scalars['String']>;
  surfacageCouvertureVerso?: InputMaybe<Scalars['String']>;
  surfacageInterieurRecto?: InputMaybe<Scalars['String']>;
  surfacageInterieurVerso?: InputMaybe<Scalars['String']>;
  taille?: InputMaybe<Scalars['String']>;
  tauxSurfacage?: InputMaybe<Scalars['String']>;
  typeEnvoi?: InputMaybe<Scalars['String']>;
  typeImpression?: InputMaybe<Scalars['String']>;
  typeMatiere?: InputMaybe<Scalars['String']>;
  typeMatiereCouverture?: InputMaybe<Scalars['String']>;
  typeMatiereInterieur?: InputMaybe<Scalars['String']>;
  typePapier?: InputMaybe<Scalars['String']>;
  typeSupport?: InputMaybe<Scalars['String']>;
  zoneTerritorial?: InputMaybe<Scalars['String']>;
};

export type ProductsFilterInput = {
  attributes?: InputMaybe<AttributesFilterInput>;
  clientId?: InputMaybe<Scalars['String']>;
  clientPriceStatus?: InputMaybe<ClientPriceStatus>;
  supportCategory?: InputMaybe<SupportCategory>;
  supportId?: InputMaybe<Scalars['String']>;
  supportName?: InputMaybe<Scalars['String']>;
};

export enum ProjectName {
  OnePartners = 'OnePartners',
  OneSupply = 'OneSupply',
}

export type PublishData = {
  __typename?: 'PublishData';
  postId: Scalars['String'];
  postUrl: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  allClientsPrices: Array<Client>;
  attributes: Array<Attribute>;
  authUser: User;
  bestSellingOrders: Array<BestSellingOrders>;
  bigShopConfig: BigShopConfig;
  client: Client;
  clientAndAllChildrens: Array<Client>;
  clientAndAllParents: Array<Client>;
  clientByCompanyBase: ClientShippingAddressByCompanyBase;
  clientChildren: Array<ClientChildren>;
  clientPrice: ClientPrice;
  clientPriceWithCustomQuantity: ClientPrice;
  clientPrices: Array<ClientPrice>;
  clientPricesByClientId: Array<ClientPrice>;
  clientPricesByClientIdAndSupportId: Array<ClientPrice>;
  clientPricesByProductNameAndClientId: Array<ClientPrice>;
  clientPricesByProductsId: Array<Client>;
  clientQuote: ClientQuote;
  clientQuotes: Array<ClientQuote>;
  clientShippingAddress: Array<ClientShippingAddress>;
  clients: Array<Client>;
  countedInvoices: InvoiceWithPagination;
  currentUser: User;
  currentUserClients: Array<Client>;
  department: Scalars['String'];
  departments: Array<Department>;
  exportClientsPrices: Scalars['String'];
  fabricationTimes: FabricationTimeBySupport;
  fileReferences: FileReferenceWithPaginationModel;
  filteredClientQuotes: Array<ClientQuote>;
  filtredClientPrice?: Maybe<ClientPrice>;
  filtredSupplierPrices: Array<SupplierPrices>;
  getClientPricesFromDistributions: Array<ClientPricesDistributions>;
  getSupplierPricesForOrderItem: Array<SupplierPrices>;
  invoice: Invoice;
  invoices: InvoiceWithPagination;
  mainShippingAddress: ClientShippingAddress;
  order: Order;
  orderDeliveries: OrderDeliveries;
  orders: Array<Order>;
  password: Password;
  permissions: Array<Permission>;
  possibleBillingsClients: Array<Client>;
  printValues: Array<PrintValue>;
  printValuesByAttributeId: Array<PrintValue>;
  printValuesCharacteristicsByFamily: Array<Attribute>;
  product: Product;
  productClientPriceWithCustomQuantity: ProductClientPrice;
  products: Array<Product>;
  quoteAttributes: Array<QuoteAttributesConfig>;
  role: Role;
  roles: Array<Role>;
  supplier: Supplier;
  supplierOrdersPaginated: SupplierOrdersWithTotal;
  suppliers: Array<Supplier>;
  suppliersPrices: Array<Supplier>;
  support: Support;
  supports: Array<Support>;
  transportListWithPurchasePrice: Array<TransportersPricesModel>;
  transporters: Array<Transporter>;
  transportersPricesForOrderItem: Array<TransportersPricesModel>;
  user: User;
  users: Array<User>;
  usersByClientIds: Array<User>;
};

export type QueryBestSellingOrdersArgs = {
  clientId: Scalars['String'];
  pagination?: InputMaybe<PaginationInput>;
};

export type QueryBigShopConfigArgs = {
  supportId: Scalars['String'];
};

export type QueryClientArgs = {
  id: Scalars['String'];
};

export type QueryClientAndAllChildrensArgs = {
  id: Scalars['String'];
};

export type QueryClientAndAllParentsArgs = {
  id: Scalars['String'];
};

export type QueryClientByCompanyBaseArgs = {
  companyBase: Scalars['String'];
  filter?: InputMaybe<ClientFilterInputByCompanyBaseInput>;
};

export type QueryClientChildrenArgs = {
  id: Scalars['String'];
};

export type QueryClientPriceArgs = {
  filters: UniqClientPriceInput;
};

export type QueryClientPriceWithCustomQuantityArgs = {
  filters: UniqClientPriceInput;
};

export type QueryClientPricesArgs = {
  filters: ClientPricesFiltersInput;
};

export type QueryClientPricesByClientIdArgs = {
  filter: ClientPricesFiltersInput;
};

export type QueryClientPricesByClientIdAndSupportIdArgs = {
  clientId: Scalars['String'];
  supportId: Scalars['String'];
};

export type QueryClientPricesByProductNameAndClientIdArgs = {
  filters: ClientPricesFiltersInput;
};

export type QueryClientPricesByProductsIdArgs = {
  productsId: Array<Scalars['String']>;
};

export type QueryClientQuoteArgs = {
  id: Scalars['String'];
};

export type QueryClientShippingAddressArgs = {
  clientId?: InputMaybe<Scalars['String']>;
};

export type QueryClientsArgs = {
  filter?: InputMaybe<ClientFilterInput>;
};

export type QueryCountedInvoicesArgs = {
  filter?: InputMaybe<InvoiceFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type QueryCurrentUserClientsArgs = {
  hasChildren: Scalars['Boolean'];
};

export type QueryDepartmentArgs = {
  filter: DepartmentInput;
};

export type QueryExportClientsPricesArgs = {
  data: ProductsFilterInput;
};

export type QueryFabricationTimesArgs = {
  input: FabricationTimeBySupportInput;
};

export type QueryFileReferencesArgs = {
  filters?: InputMaybe<FileReferenceFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<FileReferenceSearchInput>;
  sort?: InputMaybe<FileReferenceSortInput>;
};

export type QueryFilteredClientQuotesArgs = {
  filters: ClientQuoteFilterInput;
};

export type QueryFiltredClientPriceArgs = {
  filters: FiltredClientPriceInput;
};

export type QueryFiltredSupplierPricesArgs = {
  filters: SupplierPriceFilterInput;
};

export type QueryGetClientPricesFromDistributionsArgs = {
  filters: ClientPricesDistributionsInput;
};

export type QueryGetSupplierPricesForOrderItemArgs = {
  options: SupplierPricesForOrderItemInput;
};

export type QueryInvoiceArgs = {
  id: Scalars['String'];
};

export type QueryInvoicesArgs = {
  filter?: InputMaybe<InvoiceFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type QueryMainShippingAddressArgs = {
  clientId: Scalars['String'];
};

export type QueryOrderArgs = {
  id: Scalars['String'];
};

export type QueryOrderDeliveriesArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type QueryOrdersArgs = {
  filter?: InputMaybe<OrderFilterInput>;
};

export type QueryPossibleBillingsClientsArgs = {
  id: Scalars['String'];
};

export type QueryPrintValuesByAttributeIdArgs = {
  id: Scalars['String'];
};

export type QueryPrintValuesCharacteristicsByFamilyArgs = {
  family: Scalars['String'];
};

export type QueryProductArgs = {
  id: Scalars['String'];
};

export type QueryProductClientPriceWithCustomQuantityArgs = {
  filters: UniqClientPriceInput;
};

export type QueryProductsArgs = {
  data: ProductsFilterInput;
};

export type QueryQuoteAttributesArgs = {
  clientId: Scalars['String'];
};

export type QueryRoleArgs = {
  id: Scalars['String'];
};

export type QuerySupplierArgs = {
  id: Scalars['String'];
};

export type QuerySupplierOrdersPaginatedArgs = {
  filter?: InputMaybe<SupplierOrderFilterInput>;
  limit: Scalars['Float'];
  offset: Scalars['Float'];
};

export type QuerySuppliersPricesArgs = {
  productsId?: InputMaybe<Array<Scalars['String']>>;
};

export type QuerySupportArgs = {
  id: Scalars['String'];
};

export type QuerySupportsArgs = {
  filters?: InputMaybe<SupportFiltersInput>;
};

export type QueryTransportListWithPurchasePriceArgs = {
  filter: UniqClientPriceInput;
};

export type QueryTransportersArgs = {
  filter?: InputMaybe<TransporterFilterInput>;
};

export type QueryTransportersPricesForOrderItemArgs = {
  options: TransportersPricesForOrderItemInput;
};

export type QueryUserArgs = {
  id: Scalars['String'];
};

export type QueryUsersByClientIdsArgs = {
  clientId: Scalars['String'];
  roleNames?: InputMaybe<Array<Scalars['String']>>;
};

export type QuoteAttributesConfig = {
  __typename?: 'QuoteAttributesConfig';
  familiesGroup: Scalars['String'];
  familyAttributes: Array<FamilyAttributes>;
};

export type QuoteClient = {
  elpevSite: ElpevSite;
  translation: ClientQuoteTranslate;
};

export type QuoteClientModel = {
  __typename?: 'QuoteClientModel';
  elpevSite: ElpevSite;
  translation: ClientQuoteTranslate;
};

export enum QuoteType {
  ClientOrderToBeCreate = 'ClientOrderToBeCreate',
  ClientPriceToBeValidate = 'ClientPriceToBeValidate',
}

export type Resource = {
  __typename?: 'Resource';
  mimetype: Scalars['String'];
  resourceId: Scalars['String'];
  resourceIndex: Scalars['Float'];
};

export type ResourceInput = {
  mimetype: Scalars['String'];
  resourceId: Scalars['String'];
  resourceIndex: Scalars['Float'];
};

export type ResourceNetwork = {
  __typename?: 'ResourceNetwork';
  mimetype: Scalars['String'];
  resourceId: Scalars['String'];
  resourceIndex: Scalars['Float'];
};

export type ResponsableUserInitials = {
  __typename?: 'ResponsableUserInitials';
  fullName?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  users: Array<User>;
};

export type RoleInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  permissionKeys: Array<Scalars['String']>;
};

export enum Rythme {
  Annuel = 'Annuel',
  Semestriel = 'Semestriel',
  Trimestriel = 'Trimestriel',
}

export type SocialNetworksInput = {
  category: Scalars['String'];
  clientNetworkLink?: InputMaybe<Scalars['String']>;
  clientNetworkPageId?: InputMaybe<Scalars['String']>;
  expectedDeliveryDate: Scalars['DateTime'];
  mediumType: Scalars['String'];
  publishDescription?: InputMaybe<Scalars['String']>;
  resources?: InputMaybe<Array<ResourceInput>>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum Source {
  OnePacBigShop = 'OnePacBigShop',
  OneSupply = 'OneSupply',
}

export type StringFilter = {
  equals?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<Scalars['String']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  comment?: Maybe<Scalars['String']>;
  companyBase?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  discounts?: Maybe<Array<DiscountObjectType>>;
  email?: Maybe<Scalars['String']>;
  escomptes?: Maybe<Array<EscompteObjectType>>;
  expressMode?: Maybe<Scalars['Boolean']>;
  expressModeSupplement?: Maybe<Scalars['Float']>;
  fax?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActif: Scalars['Boolean'];
  lastConnection: Scalars['DateTime'];
  maxPalletWeight?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  productsSuppliersPrices?: Maybe<Array<SupplierPrices>>;
  rfa?: Maybe<Scalars['Float']>;
  rsp?: Maybe<Scalars['Float']>;
  rspEnd?: Maybe<Scalars['DateTime']>;
  rspStart?: Maybe<Scalars['DateTime']>;
  rythme?: Maybe<Rythme>;
  siteAddress1?: Maybe<Address>;
  siteAddress2?: Maybe<Address>;
  supplierContact: Array<Contact>;
  transportModes?: Maybe<Array<TransportModeObjectType>>;
  tvaNum?: Maybe<Scalars['String']>;
  tvaType?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  urgentMode?: Maybe<Scalars['Boolean']>;
  urgentModeSupplement?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
  website?: Maybe<Scalars['String']>;
};

export type SupplierOrder = {
  __typename?: 'SupplierOrder';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Order;
  orderId: Scalars['String'];
  purchaseOrderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupplierOrderStatus>;
  supplierId: Scalars['String'];
};

export type SupplierOrderFilterInput = {
  status?: InputMaybe<SupplierOrderStatus>;
  supplierId?: InputMaybe<Scalars['String']>;
};

export type SupplierOrderInsertInput = {
  orderId: Scalars['String'];
  purchaseOrderId?: InputMaybe<Scalars['String']>;
  supplierId: Scalars['String'];
};

export enum SupplierOrderStatus {
  Canceled = 'Canceled',
  Delivered = 'Delivered',
  PendingDelivery = 'PendingDelivery',
  PendingProduction = 'PendingProduction',
  PendingValidation = 'PendingValidation',
  Refused = 'Refused',
}

export type SupplierOrdersWithTotal = {
  __typename?: 'SupplierOrdersWithTotal';
  supplierOrders: Array<SupplierOrder>;
  total: Scalars['Float'];
};

export type SupplierPriceFilterInput = {
  isCheapestSupplier?: InputMaybe<Scalars['Boolean']>;
  isIdentical: Scalars['Boolean'];
  numberVersion: Scalars['Float'];
  productAttributes?: InputMaybe<ProductAttributesValues>;
  productId?: InputMaybe<Scalars['String']>;
  quantities: Array<Scalars['Float']>;
  quantity: Scalars['Float'];
  supplierId?: InputMaybe<Scalars['String']>;
  supportId: Scalars['String'];
  tools: Scalars['Boolean'];
};

export type SupplierPrices = {
  __typename?: 'SupplierPrices';
  autrePrixFixe?: Maybe<Scalars['Float']>;
  boxesInfo?: Maybe<Array<BoxesInfo>>;
  delais?: Maybe<Scalars['Float']>;
  departingDepartment?: Maybe<Scalars['String']>;
  franco?: Maybe<Scalars['Boolean']>;
  nbPosesMax?: Maybe<Scalars['Float']>;
  poidsUnitaireGr?: Maybe<Scalars['Float']>;
  prixCalage?: Maybe<Scalars['Float']>;
  prixChangementPlaque?: Maybe<Scalars['Float']>;
  prixCliche?: Maybe<Scalars['Float']>;
  prixExemplaireSupp?: Maybe<Scalars['Float']>;
  prixHorsCalage?: Maybe<Scalars['Float']>;
  prixOutillage?: Maybe<Scalars['Float']>;
  prixPapierKg?: Maybe<Scalars['Float']>;
  prixTraitementFichier?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  rfa?: Maybe<Scalars['Float']>;
  supplier: Supplier;
  supplierId: Scalars['String'];
  supplierName?: Maybe<Scalars['String']>;
  totalPAHT?: Maybe<Scalars['Float']>;
  totalPAHTWithRFA?: Maybe<Scalars['Float']>;
  validityDate?: Maybe<Scalars['DateTime']>;
};

export type SupplierPricesForOrderItemInput = {
  distributions?: InputMaybe<Array<Scalars['Float']>>;
  numberVersion: Scalars['Float'];
  productId: Scalars['String'];
  quantityGlobal: Scalars['Float'];
  supportId: Scalars['String'];
};

export type SupplierUpdateInput = {
  comment?: InputMaybe<Scalars['String']>;
  companyBase?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<Array<DiscountInput>>;
  email?: InputMaybe<Scalars['String']>;
  escomptes?: InputMaybe<Array<EscompteInput>>;
  expressMode?: InputMaybe<Scalars['Boolean']>;
  expressModeSupplement?: InputMaybe<Scalars['Float']>;
  fax?: InputMaybe<Scalars['String']>;
  isActif?: InputMaybe<Scalars['Boolean']>;
  maxPalletWeight?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  rfa?: InputMaybe<Scalars['Float']>;
  rsp?: InputMaybe<Scalars['Float']>;
  rspEnd?: InputMaybe<Scalars['DateTime']>;
  rspStart?: InputMaybe<Scalars['DateTime']>;
  rythme?: InputMaybe<Rythme>;
  siteAddress1?: InputMaybe<AddressInput>;
  siteAddress2?: InputMaybe<AddressInput>;
  transportModes?: InputMaybe<Array<TransportModeInput>>;
  tvaNum?: InputMaybe<Scalars['String']>;
  tvaType?: InputMaybe<Scalars['Float']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  urgentMode?: InputMaybe<Scalars['Boolean']>;
  urgentModeSupplement?: InputMaybe<Scalars['Float']>;
  user?: InputMaybe<UserUpdateInput>;
  website?: InputMaybe<Scalars['String']>;
};

export type Support = {
  __typename?: 'Support';
  bigShopConfig?: Maybe<BigShopConfig>;
  category?: Maybe<SupportCategory>;
  clariPrintCorrespondence?: Maybe<ClariPrintCorrespondence>;
  excludingEcotax: Scalars['Boolean'];
  fabricationTimes?: Maybe<Array<FabricationTime>>;
  id: Scalars['String'];
  isActif?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pricesSource?: Maybe<PricesSource>;
  printAttributes?: Maybe<Array<PrintValue>>;
  products?: Maybe<Array<Product>>;
  supportAttributes?: Maybe<Array<Attribute>>;
};

export enum SupportCategory {
  Digital = 'Digital',
  Plv = 'PLV',
  Print = 'Print',
  Roto = 'Roto',
}

export type SupportFiltersInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientPriceStatus?: InputMaybe<ClientPriceStatus>;
  isActif?: InputMaybe<Scalars['Boolean']>;
  withBigShopConfig?: InputMaybe<Scalars['Boolean']>;
};

export type SupportUpdateInput = {
  attributesIds: Array<Scalars['String']>;
  category?: InputMaybe<SupportCategory>;
  clariPrintCorrespondence?: InputMaybe<ClariPrintCorrespondence>;
  excludingEcotax: Scalars['Boolean'];
  fabricationTimes?: InputMaybe<Array<FabricationTimeInput>>;
  isActif: Scalars['Boolean'];
  pricesSource: PricesSource;
  supportId: Scalars['String'];
};

export enum TerritorialArea {
  PeriUrbanArea = 'PeriUrbanArea',
  PeriUrbanAreaRural = 'PeriUrbanAreaRural',
  Rural = 'Rural',
  Urban = 'Urban',
}

export type TransportModeInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type TransportModeObjectType = {
  __typename?: 'TransportModeObjectType';
  title?: Maybe<Scalars['String']>;
};

export enum TransportMoment {
  InWeek = 'InWeek',
  Saturday = 'Saturday',
}

export enum TransportType {
  DeliveryByParcel = 'deliveryByParcel',
  TrasnportBySupplier = 'trasnportBySupplier',
  WithoutTransport = 'withoutTransport',
}

export type Transporter = {
  __typename?: 'Transporter';
  corsicaDeliveryExtra: Scalars['Float'];
  expressDeliveryPrices: Array<Scalars['Float']>;
  fuelSurchargeRate: Scalars['Float'];
  hardlyAccessibleArea: Scalars['Float'];
  hardlyAccessibleAreas: Array<HardlyAccessibleAreasTransporter>;
  id: Scalars['String'];
  isActif: Scalars['Boolean'];
  maxMechanisableParcelLengthCm: Scalars['Float'];
  maxMechanisableParcelWidthCm: Scalars['Float'];
  minWeightKg: Scalars['Float'];
  name: Scalars['String'];
  nonMechanisableParcel: Scalars['Float'];
  nonRegularPickup: Scalars['Float'];
  nonStandardParcel: Scalars['Float'];
  saturdayDeliveryExtra: Scalars['Float'];
  standardDeliveryPrices: Array<Scalars['Float']>;
  supplementOverThirtyKg: Scalars['Float'];
};

export type TransporterFilterInput = {
  isActif?: InputMaybe<Scalars['Boolean']>;
};

export type TransporterIdentity = {
  __typename?: 'TransporterIdentity';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type TransporterUpdateInput = {
  corsicaDeliveryExtra: Scalars['Float'];
  expressDeliveryPrices: Array<Scalars['Float']>;
  fuelSurchargeRate: Scalars['Float'];
  hardlyAccessibleArea: Scalars['Float'];
  hardlyAccessibleAreas: Array<HardlyAccessibleAreasTransporterInput>;
  id: Scalars['String'];
  isActif: Scalars['Boolean'];
  maxMechanisableParcelLengthCm: Scalars['Float'];
  maxMechanisableParcelWidthCm: Scalars['Float'];
  minWeightKg: Scalars['Float'];
  name: Scalars['String'];
  nonMechanisableParcel: Scalars['Float'];
  nonRegularPickup: Scalars['Float'];
  nonStandardParcel: Scalars['Float'];
  saturdayDeliveryExtra: Scalars['Float'];
  standardDeliveryPrices: Array<Scalars['Float']>;
  supplementOverThirtyKg: Scalars['Float'];
};

export type TransportersPricesForOrderItemInput = {
  clientId: Scalars['String'];
  distributions?: InputMaybe<Array<Scalars['Float']>>;
  numberVersion: Scalars['Float'];
  productId: Scalars['String'];
  quantity: Scalars['Float'];
};

export type TransportersPricesModel = {
  __typename?: 'TransportersPricesModel';
  transporterId: Scalars['String'];
  transporterName: Scalars['String'];
  transporterPurchaseExcludingVATWithRfa: Scalars['Float'];
  transporterPurchasePriceExcludingVAT: Scalars['Float'];
  transporterRfaRate: Scalars['Float'];
};

export type UniqClientPriceInput = {
  clientId: Scalars['String'];
  deliveryParcel?: InputMaybe<BoxShippingMode>;
  globalQuantity?: InputMaybe<Scalars['Float']>;
  numberVersion: Scalars['Float'];
  productId: Scalars['String'];
  quantity: Scalars['Float'];
  supplierId?: InputMaybe<Scalars['String']>;
  transportMoment?: InputMaybe<TransportMoment>;
  transporterId?: InputMaybe<Scalars['String']>;
};

export type UpsertOrderInput = {
  billingAddress?: InputMaybe<ClientQuoteBillingAddressInput>;
  billingEntity?: InputMaybe<Scalars['String']>;
  buyingPriceHt?: InputMaybe<Scalars['Float']>;
  buyingPriceHtWithRfa?: InputMaybe<Scalars['Float']>;
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  clientQuoteId?: InputMaybe<Scalars['String']>;
  clientReference?: InputMaybe<Scalars['String']>;
  elepvContactName?: InputMaybe<Scalars['String']>;
  elpevContactEmail?: InputMaybe<Scalars['String']>;
  elpevContactPhone?: InputMaybe<Scalars['String']>;
  fileReferenceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isCreatePurchaseOrders?: InputMaybe<Scalars['Boolean']>;
  isDistribution: Scalars['Boolean'];
  marginRate?: InputMaybe<Scalars['Float']>;
  marginWithClientRfa?: InputMaybe<Scalars['Float']>;
  operationName?: InputMaybe<Scalars['String']>;
  orderDistributions?: InputMaybe<Array<OrderDistributionsUpsertInput>>;
  orderItem?: InputMaybe<Array<OrderItemUpsertInput>>;
  orderItemIdsToDelete: Array<Scalars['String']>;
  realMargin?: InputMaybe<Scalars['Float']>;
  sellingPriceHt?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<OrderStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  version?: InputMaybe<Scalars['Float']>;
};

export type User = {
  __typename?: 'User';
  canViewBuyingPrices: Scalars['Boolean'];
  canViewSalePrices: Scalars['Boolean'];
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['String'];
  initials?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  replacedById?: Maybe<Scalars['String']>;
  replacedFrom?: Maybe<Scalars['DateTime']>;
  replacedTo?: Maybe<Scalars['DateTime']>;
  role: Role;
  supplier: Supplier;
  supplierId?: Maybe<Scalars['String']>;
  userClients?: Maybe<Array<Client>>;
};

export type UserCreateInput = {
  canViewBuyingPrices: Scalars['Boolean'];
  canViewSalePrices: Scalars['Boolean'];
  clientIds?: InputMaybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  initials?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  replacedById?: InputMaybe<Scalars['String']>;
  replacedFrom?: InputMaybe<Scalars['DateTime']>;
  replacedTo?: InputMaybe<Scalars['DateTime']>;
  roleId?: InputMaybe<Scalars['String']>;
};

export type UserDetails = {
  __typename?: 'UserDetails';
  commercial?: Maybe<CommercialInitials>;
  responsable?: Maybe<ResponsableUserInitials>;
};

export type UserUpdateInput = {
  canViewBuyingPrices?: InputMaybe<Scalars['Boolean']>;
  canViewSalePrices?: InputMaybe<Scalars['Boolean']>;
  clientIds?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  replacedById?: InputMaybe<Scalars['String']>;
  replacedFrom?: InputMaybe<Scalars['DateTime']>;
  replacedTo?: InputMaybe<Scalars['DateTime']>;
  roleId?: InputMaybe<Scalars['String']>;
};

export type WorkingDay = {
  __typename?: 'WorkingDay';
  day: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  isWorkingDay?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['String']>;
};

export type WorkingDayInput = {
  day?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['String']>;
  isWorkingDay?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['String']>;
};

export enum ClientQuoteStatus {
  Canceled = 'Canceled',
  InProgress = 'InProgress',
  Ready = 'Ready',
  Validated = 'Validated',
  Waiting = 'Waiting',
}

export type OrderDistributions = {
  __typename?: 'orderDistributions';
  clientId: Scalars['String'];
  clientShippingAddressId: Scalars['String'];
  id: Scalars['String'];
};
