import is from 'ramda/src/is';

export const setDataLocalStorage = (<T>(key: string, value: T) => {
  if (is(String, value)) {
    localStorage.setItem(key, value);
  } else if (is(Number, value)) {
    localStorage.setItem(key, value.toString());
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
});

export const getDataLocalStorage = ((key: string) => {
  const data = localStorage.getItem(key);

  if (data) {
    if (is(Number, parseFloat(data))) {
      try {
        // data type is object
        return JSON.parse(data);
      } catch {
        // data type is string
        return data;
      }
    }
    // data type is float
    return parseFloat(data);
  }
  // data is null
  return null;
});
