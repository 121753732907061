import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography, Avatar } from '@mui/material';
import { useMemo } from 'react';
import { Column, Row } from 'react-table';
import colors from 'core/theme/colors';
import { GetSupplierOrdersQuery } from 'domains/SupplierOrders/graphql/queries.definitions';
import { styles } from 'domains/SupplierOrders/ui/SupplierOrdersTable/SupplierOrdersTable.style';
import { ReactTable } from 'shared/ui';
import {
  getEarliestDateInArray,
  displayElapsedTimeBetweenDates,
  formatDateToShortFrench,
  isDateMoreThanNHoursAgo,
} from 'shared/utils';

interface SupplierOrderTableProps {
  data: GetSupplierOrdersQuery['supplierOrdersPaginated']['supplierOrders'];
}

interface AddressCountPerProduct {
  [index: string]: {
    jsonAddresses: string[];
  }
}

const getUniqueProductCountFromOrderItems = (
  orderItems: GetSupplierOrdersQuery['supplierOrdersPaginated']['supplierOrders'][0]['order']['orderItem'],
): number => new Set(orderItems.map((item) => item.productId)).size;

const getHighestSalePointCountFromOrderItems = (
  orderItems: GetSupplierOrdersQuery['supplierOrdersPaginated']['supplierOrders'][0]['order']['orderItem'],
): number => {
  const counter = {} as AddressCountPerProduct;
  for (let index = 0; index < orderItems.length; index += 1) {
    const orderItem = orderItems[index];
    const jsonAddress = JSON.stringify(orderItem.shippingAddress);
    const productFoundInCounter = counter[orderItem.productId];
    if (productFoundInCounter) {
      if (!productFoundInCounter.jsonAddresses.includes(jsonAddress)) {
        productFoundInCounter.jsonAddresses.push(jsonAddress);
      }
    } else {
      counter[orderItem.productId] = {
        jsonAddresses: [jsonAddress],
      };
    }
  }
  const counts = Object.values(counter).map((item) => item.jsonAddresses.length);
  return Math.max(...counts);
};

const getEarliestDeliveryDateFromOrderItems = (
  orderItems: GetSupplierOrdersQuery['supplierOrdersPaginated']['supplierOrders'][0]['order']['orderItem'],
): Date => getEarliestDateInArray(orderItems.map((item) => new Date(item.expectedDeliveryDate)));

const calcPriceSumFromOrderItems = (
  orderItems: GetSupplierOrdersQuery['supplierOrdersPaginated']['supplierOrders'][0]['order']['orderItem'],
): number => orderItems.map((item) => (
  item.buyingPriceHt || 0
)).reduce((a, b) => a + b, 0);

const formatPriceToFrench = (price: number) => {
  const formattedSum = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);
  return (
    <Typography sx={styles.priceCell}>
      {formattedSum}
    </Typography>
  );
};

const getRowProps = (
  row: Row<GetSupplierOrdersQuery['supplierOrdersPaginated']['supplierOrders'][0]>,
) => {
  const condition = isDateMoreThanNHoursAgo(new Date(row.original.createdAt), 8);
  return ({
    style: {
      backgroundColor: condition ? colors.lightOrange : 'inherit',
    },
  });
};

export const SupplierOrdersTable = ({ data }: SupplierOrderTableProps) => {
  const columns = useMemo<Column<GetSupplierOrdersQuery['supplierOrdersPaginated']['supplierOrders'][0]>[]>(
    () => [
      {
        id: 'supplierOrderCreatedAt',
        Header: 'Date de mise en ligne',
        accessor: (row) => new Date(row.createdAt),
        Cell: ({ value }: { value: Date }) => formatDateToShortFrench(value),
      },
      {
        id: 'orderFileReferenceId',
        Header: 'N° dossier',
        accessor: (row) => row.order.fileReference?.id,
        Cell: ({ value }: { value: string }) => <Typography>{value}</Typography>,
      },
      {
        id: 'supplierOrderId',
        Header: 'N° commande',
        accessor: (row) => row.id,
      },
      {
        id: 'earliestDeliveryDate',
        Header: 'Date livraison',
        accessor: (row) => getEarliestDeliveryDateFromOrderItems(row.order.orderItem),
        Cell: ({ value }: { value: Date }) => formatDateToShortFrench(value),
      },
      {
        id: 'orderAskedBy',
        Header: 'Demandé par',
        accessor: (row) => row.order.elepvContactName,
      },
      {
        id: 'uniqueProductCount',
        Header: 'Produits à réaliser',
        accessor: (row) => getUniqueProductCountFromOrderItems(row.order.orderItem),
      },
      {
        id: 'highestSalePointCount',
        Header: 'Points livraison',
        accessor: (row) => getHighestSalePointCountFromOrderItems(row.order.orderItem),
      },
      {
        id: 'elapsedTimeSinceSupplierOrderCreation',
        Header: 'Temps écoulé avant validation',
        accessor: (row) => new Date(row.createdAt),
        Cell: ({ value }: { value: Date }) => (
          isDateMoreThanNHoursAgo(value, 8)
            ? (
              <Box sx={styles.lessThanEightHoursSpentTimeCell}>
                <Typography>
                  {displayElapsedTimeBetweenDates(value)}
                </Typography>
                <Avatar>
                  <ErrorOutlineIcon />
                </Avatar>
              </Box>
            )
            : displayElapsedTimeBetweenDates(value)
        ),
      },
      {
        id: 'dutyFreeBuyingPriceSum',
        Header: 'Total HT',
        accessor: (row) => calcPriceSumFromOrderItems(row.order.orderItem),
        Cell: ({ value }: { value: number }) => formatPriceToFrench(value),
      },
    ],
    [],
  );

  return (
    <ReactTable
      columns={columns}
      data={data}
      getRowProps={getRowProps}
    />
  );
};
