import { Supplier } from 'core/definitions/types';

export enum ReducerActionType {
  'LOGIN',
  'LOGOUT',
}

export interface UserLoginType {
	accessToken: string;
	currentUser: {
		id: string;
		givenName: string,
		supplier: Partial<Supplier>
	}
}

export interface ReducerAction {
  type: ReducerActionType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: Record<string, any>,
}