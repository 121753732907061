import {
  Route, Routes, Navigate,
} from 'react-router-dom';
import { AccessControl } from 'core';
import { SupplierOrders, Quotes, Login } from 'domains';
import { PageNotFound } from 'shared/ui';

const Router = () => (
  <Routes>
    <Route path="/" element={<AccessControl />}>
      <Route index element={<Navigate replace to="/orders/pending-validation" />} />
      <Route path="quotes/:quoteStatus" element={<Quotes />} />
      <Route path="orders/:supplierOrderStatus" element={<SupplierOrders />} />
      <Route path="*" element={<PageNotFound />} />
    </Route>
    <Route path="/login" element={<Login />} />
  </Routes>
);
export default Router;
