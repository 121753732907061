import colors from 'core/theme/colors';

export const styles = {
  activeSubMenu: {
    backgroundColor: colors.documentHeaderDarkActive,
    borderLeft: '2px solid',
    borderColor: colors.lightBlue,
    '&:hover': {
      backgroundColor: colors.documentHeaderDarkActive,
      borderLeft: '2px solid',
      borderColor: colors.lightBlue,
    },
  },
  link: {
    textDecoration: 'none',
  },
};
