import { yupResolver } from '@hookform/resolvers/yup';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  Box, Button, Card, Divider, Grid, IconButton, InputAdornment, TextField, Typography,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ProjectName } from 'core/definitions/types';
import colors from 'core/theme/colors';
import { LayoutField } from 'shared/ui';
import { useLogin } from './Login.hook';
import { getAuthFormSchema } from './Login.validation';

export const Login = () => {
  const loginUser = useLogin();
  const navigate = useNavigate();
  const [isLoginError, setIsLoginError] = useState<boolean>(false);

  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(getAuthFormSchema),
  });
  const [isShowPassword, setIsShowPassword] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmitLoginForm = async (loginInput: any) => {
    try {
      const result = await loginUser({ ...loginInput, projectName: ProjectName.OnePartners });
      navigate('/', { replace: true });
      return result;
    } catch (error) {
      setIsLoginError(true);
    }
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      bgcolor={colors.documentHeaderDark}
      minHeight="100%"
    >
      <Grid item xs={12} pt={8}>
        <img src={`${process.env.PUBLIC_URL}/images/onePartnersLogo.svg`} height={85} alt="OnePartners logo" />
        <Typography variant="h3" align="right" color={colors.gray300}>
          By Nanotera
        </Typography>
      </Grid>
      <Grid item xs={12} pt={4} pb={4}>
        <Typography align="center" color={colors.gray300}>
          Votre plateforme fournisseur en relation directe avec nous.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form
          onSubmit={handleSubmit(onSubmitLoginForm)}
        >
          <Card sx={{ minWidth: 440 }}>
            <Box mt={4} mb={4} textAlign="center">
              <Typography variant="h1">Connectez-vous</Typography>
            </Box>
            <Divider />
            <Box p={4}>
              <Box>
                <LayoutField label="Nom d'utilisateur">
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...register('email')}
                    helperText={errors.email && errors.email.message}
                    error={errors.email}
                  />
                </LayoutField>
              </Box>
              <Box mt={3}>
                <LayoutField label="Mot de passe">
                  <TextField
                    variant="outlined"
                    {...register('password')}
                    fullWidth
                    type={isShowPassword ? 'password' : 'text'}
                    helperText={errors.password && errors.password.message}
                    error={errors.password}
                    InputProps={{
                      autoComplete: 'on',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setIsShowPassword(!isShowPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            {isShowPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </LayoutField>
              </Box>
              <Box textAlign="center" height="30px" mt={1}>
                {isLoginError ? (
                  <Typography variant="body1" color="error">
                    Email ou mot de passe incorrect
                  </Typography>
                ) : null}
              </Box>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Se connecter
              </Button>
            </Box>
          </Card>
        </form>
      </Grid>
      <Grid item pt={4}>
        <Typography align="center" color={colors.gray300}>
          © OnePartners Inc. Tous droits réservés
        </Typography>
      </Grid>
      <Grid item mt="auto" pb={4}>
        <Typography align="center" color={colors.gray300}>
          Cookies - Confidentialité - Conditions d’utilisation
        </Typography>
      </Grid>
    </Grid>
  );
};
