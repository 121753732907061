import {
  ApolloClient, HttpLink, ApolloLink, InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import is from 'ramda/src/is';
import { getDataLocalStorage } from 'core/services';
import { GetSupplierOrdersQuery } from 'domains/SupplierOrders/graphql/queries.definitions';

const UNAUTHORIZED = 401;

const getErrorType = (type: string) => {
  switch (type) {
  case 'UNAUTHORIZED':
    return {
      message: 'Unauthenticated user.',
      statusCode: 401,
    };
  case 'SERVER_ERROR':
    return {
      message: 'Server error.',
      statusCode: 500,
    };
  default:
    return type;
  }
};

const params = {
  link: ApolloLink.from([
    onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          if (message) {
            const error = getErrorType(message.toUpperCase());
            if (!is(String, error) && error.statusCode === UNAUTHORIZED) {
              localStorage.removeItem('accessToken');
              window.location.href = `${window.location.origin}/login`;
            }
          }
        });
      }
    }),
    setContext((_, { headers }) => {
      const accessToken = getDataLocalStorage('accessToken');
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${accessToken}`,
        },
      };
    }),
    new HttpLink({
      uri: process.env.REACT_APP_ENDPOINT ?? '',
      fetchOptions: {
        notifyOnNetworkStatusChange: true,
      },
    }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          supplierOrdersPaginated: {
            keyArgs: ['filter'],
            merge(
              existing = { supplierOrders: [], total: 0 },
              incoming: GetSupplierOrdersQuery['supplierOrdersPaginated'],
            ) {
              return {
                supplierOrders: [...existing.supplierOrders, ...incoming.supplierOrders],
                total: incoming.total,
              };
            },
          },
        },
      },
    },
  }),
};

export const apolloClient = new ApolloClient(params);
