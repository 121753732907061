import colors from 'core/theme/colors';

export const styles = {
  priceCell: {
    textAlign: 'right',
    fontWeight: 'bolder',
  },
  folderCodeCell: {
    fontWeight: 'bolder',
  },
  lessThanEightHoursSpentTimeCell: {
    color: colors.orange,
    display: 'flex',
    '& .MuiTypography-root': {
      fontWeight: '600',
      marginRight: '10px',
    },
    '& .MuiAvatar-root': {
      backgroundColor: colors.orange,
      width: '22px',
      height: '22px',
      borderWidth: '3px',
      '& .MuiListItemIcon-root': {
        color: colors.white,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.25rem',
      },
    },
  },
};
