import {
  CssBaseline, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';
import {
  Column, Row, RowPropGetter, useSortBy, useTable,
} from 'react-table';
import colors from 'core/theme/colors';

const styles = {
  table: {
    '&::after': {
      content: '""',
      display: 'block',
      height: '50px',
    },
  },
  tableHead: {
    backgroundColor: colors.white,
    borderTop: '3px solid #e0e0e0',
  },
  cell: {
    fontSize: '0.75rem',
  },
};

interface Props<T extends Record<string, unknown>> {
  data: T[];
  columns: Column<T>[];
  getRowProps: (
    a: Row<T>
  ) => RowPropGetter<T>;
}

export const ReactTable = <T extends Record<string, unknown>>({
  columns,
  data,
  getRowProps,
}: Props<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );
  return (
    <>
      <CssBaseline />
      <Table sx={styles.table} data-testid="table-preset" {...getTableProps()}>
        <TableHead sx={styles.tableHead}>
          {
            headerGroups.map((headerGroup) => {
              const { key: headerRowKey, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
              return (
                <TableRow key={headerRowKey} {...restHeaderGroupProps}>
                  {
                    headerGroup.headers.map((column) => {
                      const { key: headerCellKey, ...restHeaderProps } = column.getHeaderProps();
                      return (
                        <TableCell key={headerCellKey} {...restHeaderProps} sx={styles.cell}>
                          {column.render('Header')}
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              );
            })
          }
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {
            rows.map((row) => {
              prepareRow(row);
              const { key: bodyRowKey, ...restRowProps } = row.getRowProps(getRowProps(row));
              return (
                <TableRow key={bodyRowKey} {...restRowProps}>
                  {
                    row.cells.map((cell) => {
                      const { key: bodyCellKey, ...restCellProps } = cell.getCellProps();
                      return (
                        <TableCell key={bodyCellKey} {...restCellProps} sx={styles.cell}>
                          {
                            cell.render('Cell')
                          }
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </>
  );
};
