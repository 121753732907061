import React from 'react';
import * as ReactDOM from 'react-dom';
import Core from './core/Core';

ReactDOM.render(
  <React.StrictMode>
    <Core />
  </React.StrictMode>,
  document.getElementById('root'),
);
