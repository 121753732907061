import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styles } from 'domains/App/ui/TopBar/TopBar.style';

export const TopBar = () => (
  <AppBar
    position="fixed"
    sx={styles.topBar}
  >
    <Toolbar>
      <img src={`${process.env.PUBLIC_URL}/images/onePartnersLogo.svg`} height={30} alt="" />
      <Typography
        variant="h4"
        noWrap
        component="div"
      >
        Votre plateforme fournisseur en relation directe avec nous.
      </Typography>
    </Toolbar>
  </AppBar>
);
