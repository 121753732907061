import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

interface Props {
	[x: string]: string | undefined | ReactNode;
	label?: string;
	children: ReactNode;
	alignLabel?: 'center' | 'right' | 'left';
}

export const LayoutField = ({
  label, children, alignLabel = 'left', ...rest
}: Props) => (
  <Box display="flex" flexDirection="column" {...rest}>
    <Box mb={1} textAlign={alignLabel}>
      <Typography variant="h4">{label}</Typography>
    </Box>
    {children}
  </Box>
);
